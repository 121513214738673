import {
    Box,
    Button,
    Card,
    Checkbox,
    Container,
    FormControl,
    FormLabel,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
  } from '@mui/material';
  import React, { useEffect, useState } from 'react';
  import { Helmet } from 'react-helmet-async';
  import { useLocation, useNavigate } from 'react-router-dom';
  import { FaArrowLeftLong } from 'react-icons/fa6';
  import { SlCloudUpload } from 'react-icons/sl';
  import Swal from 'sweetalert2';
  import { LoadingButton } from '@mui/lab';
  import { createCategory, createAroundUs, updateAroundUs, getBnbList, uploadFile } from '../../helper/backend_helper';
  import appConfig from '../../config';

  const UpdateAroundUs = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const userData = location?.state?.userData;
    const [formData, setFormData] = useState({ profileImage: null });
    const [name, setName] = useState(userData?.name);
    const [keyword, setKeyword] = useState(userData?.keyword);

  
   
    const [loading, setLoading] = useState(false);
    const handleFileChange = (e) => {
      const { name, files } = e.target;
  
      setFormData({
        ...formData,
        [name]: files[0],
      });
    };
  
    const [users, setUsers] = useState([]);
    console.log('users', users);
    async function fetchData() {
      try {
        const usersResponse = await getBnbList();
        setUsers(usersResponse?.data?.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  
    useEffect(() => {
      fetchData();
    }, []);
  
    const handleUpdate = async () => {
      try {
        setLoading(true);
        
        if (!name) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Name is required',
          });
          setLoading(false);
          return;
        }
       
     
       
  
        if (!keyword) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Keyword is required',
          });
          setLoading(false);
          return;
        }
       
       
        // Prepare headers for multipart form data
        const headers = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
      
        let imageUploadResponse; // Declare it outside the block

      if (formData.profileImage) {
        const data = new FormData();
        data.append('file', formData.profileImage);

        imageUploadResponse = await uploadFile(data, headers); // Assign value inside the block
        console.log(imageUploadResponse?.data?.data?.fileName);

        if (imageUploadResponse.status !== 200) {
          throw new Error('Image upload failed');
        }
      }
      
  
        const Categorydata = {
          name,
          keyword,
          image: imageUploadResponse?.data?.data?.fileName || userData?.image,
        };
        console.log(Categorydata);
        const Categoryheaders = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
  
        const response = await updateAroundUs(userData?._id,Categorydata, Categoryheaders);
  
     
  
        // Show success message
        Swal.fire({
          icon: 'success',
          title: 'Neighborhood updated successfully',
        });
      
       
  
        setLoading(false);
      } catch (error) {
        console.error('Error:', error);
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `${error?.response?.data?.errors[0]?.message}`,
        });
      }
    };
  
    return (
      <>
        <Helmet>
          <title> update Around Us </title>
        </Helmet>
        <Card sx={{ padding: 3 }}>
          <Stack
            onClick={() => navigate(-1)}
            gap={3}
            sx={{ cursor: 'pointer', mb: 3 }}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <FaArrowLeftLong size={24} />
            <Typography variant="h4">Update Neighborhood
            </Typography>
          </Stack>
  
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { width: '50ch' },
            }}
            noValidate
            autoComplete="off"
          >
           
           
            <Stack sx={{ marginTop: 2 }}>
              <FormLabel sx={{ mb: 1 }}>Name</FormLabel>
              <TextField
                label="Enter Name"
                id="outlined-size-small"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Stack>
            <Stack sx={{ marginTop: 2 }}>
              <FormLabel sx={{ mb: 1 }}>Keyword</FormLabel>
              <TextField
                label="Enter Keyword"
                id="outlined-size-small"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
            </Stack>
  
        
  
            <Stack
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 2,
              marginTop: 2,
            }}
          >
            <Stack>
              {!formData.profileImage ? (
                <img
                  src={`${appConfig.FILE_URL}${userData?.image}`}
                  alt="img"
                  style={{ maxWidth: '250px',padding:5 }}
                />
              ) : (
                <img
                  src={URL.createObjectURL(formData.profileImage)}
                  alt="img"
                  style={{ maxWidth: '250px',padding:5 }}
                />
              )}
            </Stack>
            <Button
              sx={{
                width: 170,
                color: '#3A5239',
                borderColor: '#3A5239',
                '&:hover': {
                  borderColor: '#3A5239',
                  boxShadow: 'none',
                },
              }}
              component="label"
              variant="outlined"
              size="medium"
              startIcon={<SlCloudUpload />}
            >
              upload Image
              <input
                type="file"
                accept="image/*"
                name="profileImage"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            </Button>
          </Stack>
          </Box>
          <LoadingButton
            sx={{
              width: 100,
              mt: 4,
              mb: 4,
              boxShadow: 'none',
              backgroundColor: '#3A5239',
              '&:hover': {
                backgroundColor: '#3A5239',
                boxShadow: 'none',
              },
            }}
            variant="contained"
            onClick={handleUpdate}
            loading={loading}
          >
            Save
          </LoadingButton>
        </Card>
      </>
    );
  };
  
  export default UpdateAroundUs;
  