// component
import SvgColor from '../../../components/svg-color';
import pls from '../../../assets/PLS.png';
import Iconify from '../../../components/iconify';
import { ROLES } from '../../../constant/constant';

// ----------------------------------------------------------------------
const icon = (name) => (
  <img
    src={`/assets/icons/navbar/${name}.svg`}
    alt={name}
    style={{ width: '22px', height: '22px' }}
  />
);
const user = localStorage.getItem('technisches');
 const userData =   JSON.parse(user)
 console.log(userData)

 const getNavConfig = (role) => {
  if (role === ROLES.ADMIN) {
    return [
      {
        title: 'dashboard',
        path: '/app',
        //  icon: icon('ic_analytics'),
        icon: <Iconify icon={'eva:grid-fill'} width={20} sx={{ cursor: 'pointer' }} />,
      },
     
      {
        title: 'Process Bookings',
        path: '/processBookings',
        // icon: icon('ic_user'),
        icon: <Iconify icon={'eva:plus-square-fill'} width={20} sx={{ cursor: 'pointer' }} />,
      },
      {
        title: 'Users',
        path: '/users',
        // icon: icon('ic_user'),
        icon: <Iconify icon={'eva:people-fill'} width={20} sx={{ cursor: 'pointer' }} />,
      },
      {
        title: 'BnB List',
        path: '/bnbList',
        // icon: icon('ic_user'),
        icon: <Iconify icon={'mdi-view-grid-plus'} width={20} sx={{ cursor: 'pointer' }} />,
      },
     
      {
        title: 'Door List',
        path: '/doorList',
        // icon: icon('ic_notification_package'),
        icon: <Iconify icon={'mdi-bullhorn'} width={20} sx={{ cursor: 'pointer' }} />,
      },
      {
        title: 'Special Access',
        path: '/specialAccess',
        // icon: icon('ic_notification_package'),
        icon: <Iconify icon={'eva:link-2-outline'} width={20} sx={{ cursor: 'pointer' }} />,
      },
      {
        title: 'Travel Guide',
        path: '/travelGuide', // Parent path
        icon: <Iconify icon={'eva:globe-2-outline'} width={20} sx={{ cursor: 'pointer' }} />,
        children: [
          {
            title: 'City',
            path: '/travelGuide/city',
            icon: <Iconify icon={'eva:shake-outline'} width={20} sx={{ cursor: 'pointer' }} />,
          },
          {
            title: 'Assign City',
            path: '/travelGuide/assignCity',
            icon: <Iconify icon={'eva:shake-outline'} width={20} sx={{ cursor: 'pointer' }} />,
          },
          {
            title: 'Category',
            path: '/travelGuide/category',
            icon: <Iconify icon={'eva:layers-outline'} width={20} sx={{ cursor: 'pointer' }} />,
          },
          {
            title: 'Travel Guide Items',
            path: '/travelGuide/info',
            icon: <Iconify icon={'eva:globe-2-outline'} width={20} sx={{ cursor: 'pointer' }} />,
          },
        ],
      },
      {
        title: 'Refill Detail',
        path: '/refill', // Parent path
        icon: <Iconify icon={'eva:shopping-bag-outline'} width={20} sx={{ cursor: 'pointer' }} />,
        children: [
          {
            title: 'Refill Items',
            path: '/Refill Detail/refillItems',
            icon: <Iconify icon={'eva:shake-outline'} width={20} sx={{ cursor: 'pointer' }} />,
          },
          {
            title: 'Assign Refill',
            path: '/Refill Detail/assignRefill',
            icon: <Iconify icon={'eva:shake-outline'} width={20} sx={{ cursor: 'pointer' }} />,
          },
          {
            title: 'Refill Requests',
            path: '/Refill Detail/refillRequest',
            icon: <Iconify icon={'eva:layers-outline'} width={20} sx={{ cursor: 'pointer' }} />,
          },
        
        ],
      },
      {
        title: 'Cleaning Detail',
        path: '/cleaning', // Parent path
        icon: <Iconify icon={'eva:shopping-bag-outline'} width={20} sx={{ cursor: 'pointer' }} />,
        children: [
          {
            title: 'Cleaning Items',
            path: '/cleaning/cleaningItems',
            icon: <Iconify icon={'eva:shake-outline'} width={20} sx={{ cursor: 'pointer' }} />,
          },
          {
            title: 'Assign Cleaning',
            path: '/cleaning/assignCleaning',
            icon: <Iconify icon={'eva:shake-outline'} width={20} sx={{ cursor: 'pointer' }} />,
          },
          {
            title: 'Cleaning Requests',
            path: '/cleaning/cleaningRequest',
            icon: <Iconify icon={'eva:layers-outline'} width={20} sx={{ cursor: 'pointer' }} />,
          },
        
        ],
      },
      {
        title: 'WebApp Settings',
        path: '/webappSettings',
        // icon: icon('ic_notification_package'),
        icon: <Iconify icon={'eva:phone-call-fill'} width={20} sx={{ cursor: 'pointer' }} />,
      },
      {
        title: 'Cleaner',
        path: '/cleaner',
        // icon: icon('ic_notification_package'),
        icon: <Iconify icon={'eva:clipboard-outline'} width={20} sx={{ cursor: 'pointer' }} />,
      },
      {
        title: 'Cleaning Report',
        path: '/cleaningReport',
        // icon: icon('ic_notification_package'),
        icon: <Iconify icon={'eva:clipboard-outline'} width={20} sx={{ cursor: 'pointer' }} />,
      },
      {
        title: 'FAQs',
        path: '/faqs',
        // icon: icon('ic_notification_package'),
        icon: <Iconify icon={'eva:question-mark-circle-outline'} width={20} sx={{ cursor: 'pointer' }} />,
      },
      {
        title: 'Maintanance',
        path: '/maintanance',
        // icon: icon('ic_notification_package'),
        icon: <Iconify icon={'eva:settings-outline'} width={20} sx={{ cursor: 'pointer' }} />,
      },
      {
        title: 'Taxi',
        path: '/texi',
        // icon: icon('ic_notification_package'),
        icon: <Iconify icon={'eva:car-outline'} width={20} sx={{ cursor: 'pointer' }} />,
      },
      {
        title: 'Around Us',
        path: '/aroundUs',
        // icon: icon('ic_notification_package'),
        icon: <Iconify icon={'eva:home-outline'} width={20} sx={{ cursor: 'pointer' }} />,
      },
      {
        title: 'Auth Token',
        path: '/authToken',
        // icon: icon('ic_notification_package'),
        icon: <Iconify icon={'eva:message-square-fill'} width={20} sx={{ cursor: 'pointer' }} />,
      },
      
    
      {
        title: 'Log out',
        path: '/logout',
        // icon: icon('ic_notification_package'),
        icon: <Iconify icon={'eva:log-out-fill'} width={20} sx={{ cursor: 'pointer' }} />,
      },
    ];
  }  if (role === ROLES.TRAVEL_GUIDER) {
    return [
      {
        title: 'dashboard',
        path: '/app',
        //  icon: icon('ic_analytics'),
        icon: <Iconify icon={'eva:grid-fill'} width={20} sx={{ cursor: 'pointer' }} />,
      },
    {
      title: 'Travel Guide',
      path: '/travelGuide', // Parent path
      icon: <Iconify icon={'eva:globe-2-outline'} width={20} sx={{ cursor: 'pointer' }} />,
      children: [
        {
          title: 'City',
          path: '/travelGuide/city',
          icon: <Iconify icon={'eva:shake-outline'} width={20} sx={{ cursor: 'pointer' }} />,
        },
        {
          title: 'Assign City',
          path: '/travelGuide/assignCity',
          icon: <Iconify icon={'eva:shake-outline'} width={20} sx={{ cursor: 'pointer' }} />,
        },
        {
          title: 'Category',
          path: '/travelGuide/category',
          icon: <Iconify icon={'eva:layers-outline'} width={20} sx={{ cursor: 'pointer' }} />,
        },
        {
          title: 'Travel Guide Items',
          path: '/travelGuide/info',
          icon: <Iconify icon={'eva:globe-2-outline'} width={20} sx={{ cursor: 'pointer' }} />,
        },
      ],
    }]
  } 
    return [
      {
        title: 'dashboard',
        path: '/app',
        //  icon: icon('ic_analytics'),
        icon: <Iconify icon={'eva:grid-fill'} width={20} sx={{ cursor: 'pointer' }} />,
      },
      {
        title: 'Maintanance',
        path: '/maintanance',
        icon: <Iconify icon={'eva:settings-outline'} width={20} sx={{ cursor: 'pointer' }} />,
      },
    ];
  
};



  const navConfig = getNavConfig(userData?.user?.role?.name);
  export default navConfig;
