export const POST_LOGIN = '/auth/login';

export const GET_USERS = '/user'
// export const CREATE_USER = '/user/create'
// export const DELETE_USER = '/user/delete'
// export const UPDATE_USER = '/user/update'
export const GET_SOLUTIONS = '/solution/get-all-solutions'
export const GET_ANNOUNCEMENT = '/announcement'
export const GET_CATEGORIES = '/category'
// export const DELETE_CATEGORY = '/category/delete'
// export const UPDATE_CATEGORY = '/category/update'
export const CREATE_SOLUTION = '/solution'
export const UPLOAD_FILE = '/upload'
export const DELETE_SOLUTION = '/solution/delete'
export const GET_BANNER = "/banner"
export const DELETE_UPLOADS = "/upload/delete"

export const CREATE_MAINTANCE  = "/maintance"
export const UPDATE_MAINTANCE = "/maintance/update"



export const REFILL_REQUEST  = "/refillRequest"
export const UPDATE_REFILL_REQUEST = "/refillRequest/update"
export const DELETE_REFILL_REQUEST = "/refillRequest/delete"

export const EXTRA_CLEANING_REQUEST  = "/extraCleaningRequest"
export const UPDATE_EXTRA_CLEANING_REQUEST = "/extraCleaningRequest/update"
export const DELETE_EXTRA_CLEANING_REQUEST = "/extraCleaningRequest/delete"
// ============================================================================

export const GET_BOOKINGS = '/processBooking'
export const GET_BNBLIST  = "/bnbList"
export const GET_DOORLIST  = "/doorList"
export const DEL_DOORLIST  = "/doorList/delete"
export const CREATE_DOORLIST  = "/doorList/create"
export const UPDATE_DOORLIST  = "/doorList/update"
export const CONTACTINFO  = "/contactInfo"

export const QUESTIONS  = "/question"
export const DEL_QUESTIONS  = "/question/delete"
export const UPDATE_QUESTIONS  = "/question/update"
export const AUTH_TOKEN = "/authToken"

export const SPECIAL_ACCESSS = "/specialAccess"
export const SPECIAL_ACCESS_DELETE = "/specialAccess/delete"
export const SPECIAL_ACCESS_UPDATE = "/specialAccess/update"


export const TRAVEL_GUIDE = "/travelGuide"
export const UPDATE_TRAVEL_GUIDE = "/travelGuide/update"


export const CITY = "/city"
export const DELETE_CITY = "/city/delete"
export const UPDATE_CITY = "/city/update"


export const GET_CATEGORIES_OF_CITY = "/category/get-Categories-of-City"

export const CATEGORY = "/category"
export const DELETE_CATEGORY = "/category/delete"
export const UPDATE_CATEGORY = "/category/update"


export const AssignCity = "/assignCity"
export const DELETE_ASSIGN_CITY = "/assignCity/delete"
export const UPDATE_ASSIGN_CITY = "/assignCity/update"


export const REFRESH_CITIES  = "/bnbList/saveCity"

export const getBnBCities  = "/bnbList/getAllBnBCities"



export const TEXI = "/texi"
export const DELETE_TEXI = "/texi/delete"
export const UPDATE_TEXI = "/texi/update"




export const AROUNDUS = "/aroundUs"
export const DELETE_AROUNDUS = "/aroundUs/delete"
export const UPDATE_AROUNDUS = "/aroundUs/update"




export const REFILL = "/refillItems"
export const DELETE_REFILL = "/refillItems/delete"
export const UPDATE_REFILL = "/refillItems/update"



export const ASSIGN_REFILL = "/assignRefill"
export const DELETE_ASSIGN_REFILL = "/assignRefill/delete"
export const UPDATE_ASSIGN_REFILL = "/assignRefill/update"





export const CLEANING_ITEMS = "/cleaningItem"
export const DELETE_CLEANING_ITEMS = "/cleaningItem/delete"
export const UPDATE_CLEANING_ITEMS = "/cleaningItem/update"


export const ASSIGN_CLEANING = "/assignCleaning"
export const DELETE_ASSIGN_CLEANING = "/assignCleaning/delete"
export const UPDATE_ASSIGN_CLEANING = "/assignCleaning/update"



export const CREATE_USER = "user/sign-up"
export const UPDATE_USER = "user/update"
export const DELETE_USER = "user/delete"
export const GET_ALL_USER = "user/getAll"
export const ROLE = "role/list"



export const CLEANER = "/cleaner"
export const CLEANER_DELETE = "/cleaner/delete"
export const CLEANER_UPDATE = "/cleaner/update"


export const GET_CLEANINGREPORT  = "/cleaningReport"


export const CLEANER_REPORT_DELETE = "/cleaningReport/delete"