import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { SlCloudUpload } from 'react-icons/sl';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';
import {
  createCategory,
  createDoorList,
  createSpecialAccesss,
  getBnbList,
  uploadFile,
} from '../../helper/backend_helper';

const AddSpecialAccess = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ profileImage: null });
  const [name, setName] = useState('');
  const [bnb, setBnB] = useState([]);
  const [description, setDescription] = useState('');
  const [expiryDate, setExpiryDate] = useState(null); 
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const handleDateChange = (newValue) => {
    setExpiryDate(newValue); // Update state when date is selected
  };
  const [loading, setLoading] = useState(false);
  const handleFileChange = (e) => {
    const { name, files } = e.target;

    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const [users, setUsers] = useState([]);
  console.log('users', users);
  async function fetchData() {
    try {
      const usersResponse = await getBnbList();
      setUsers(usersResponse?.data?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleSave = async () => {
    try {
      setLoading(true);

      if (!name) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Name is required',
        });
        setLoading(false);
        return;
      }

      if (bnb.length===0 && isChecked===false) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'BnB Name is required',
        });
        setLoading(false);
        return;
      }

      // Prepare headers for multipart form data

      const Categorydata = {
        name,
        bnb,
        description,
        expiryDate: new Date(expiryDate).toISOString(),
        allBnBs: isChecked,
      };

      const Categoryheaders = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await createSpecialAccesss(Categorydata, Categoryheaders);

      Swal.fire({
        icon: 'success',
        title: 'Special Access created successfully',
      });
      setName('');
      setBnB([]);
      setDescription('');
      setExpiryDate(null);

      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `${error?.response?.data?.errors[0]?.message}`,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title> Add SpecialAccess </title>
      </Helmet>
      <Card sx={{ padding: 3 }}>
        <Stack
          onClick={() => navigate(-1)}
          gap={3}
          sx={{ cursor: 'pointer', mb: 3 }}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <FaArrowLeftLong size={24} />
          <Typography variant="h4">Add Special Access</Typography>
        </Stack>

        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { width: '50ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <Stack spacing={{ xs: 2, sm: 4 }} direction="row" useFlexGap flexWrap="wrap">
            <Stack>
              <FormLabel sx={{ mb: 1 }}>Name</FormLabel>
              <TextField
                label="Enter Name"
                id="outlined-size-small"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Stack>
          </Stack>
          <Stack sx={{ marginTop: 2, display: 'flex', flexDirection: 'row', gap: 1 }}>
            <Typography variant="h6" mt={1}>
              All BnBs:
            </Typography>
            <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
          </Stack>
          <Stack spacing={{ xs: 2, sm: 4 }} mt={2} direction="row" useFlexGap flexWrap="wrap">
            <FormControl sx={{ width: 490 }}>
              <FormLabel sx={{ mb: 1 }}>BnB</FormLabel>
              <Select
                disabled={isChecked}
                labelId="demo-multi-select-label"
                id="demo-multi-select"
                multiple
                value={bnb} // This will be an array
                onChange={(e) => setBnB(e.target.value)}
                renderValue={(selected) => selected.join(', ')} // Render selected values as a comma-separated string
              >
                {users?.map((user) => (
                  <MenuItem key={user._id} value={user.BnBShortName}>
                    <Checkbox checked={bnb.indexOf(user.BnBShortName) > -1} />
                    {user.BnBShortName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>

          <Stack spacing={{ xs: 2, sm: 4 }} mt={4} direction="row" useFlexGap flexWrap="wrap">
            <DateTimePicker
              label="Select Expiry Date"
              value={expiryDate}
              onChange={handleDateChange} // Capture date and update state
              renderInput={(params) => <TextField {...params} />} // Optional: If you want to display a TextField
            />
          </Stack>

          <Stack spacing={{ xs: 2, sm: 4 }} direction="row" useFlexGap flexWrap="wrap">
            <Stack>
              <FormLabel sx={{ mt: 3 }}>Description</FormLabel>
              <TextField
                multiline
                rows={4}
                label="Enter Description"
                id="outlined-size-small"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Stack>
          </Stack>
        </Box>
        <LoadingButton
          sx={{
            width: 100,
            mt: 4,
            mb: 4,
            boxShadow: 'none',
            backgroundColor: '#3A5239',
            '&:hover': {
              backgroundColor: '#3A5239',
              boxShadow: 'none',
            },
          }}
          variant="contained"
          onClick={handleSave}
          loading={loading}
        >
          Save
        </LoadingButton>
      </Card>
    </>
  );
};

export default AddSpecialAccess;
