import { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
// @mui
import { Box, Collapse, List, ListItemText } from '@mui/material';
//
import { Icon } from '@iconify/react';

import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  const { pathname } = useLocation();

  const active = (path) => {
    const firstPath = pathname.split('/').slice(1, 2).join('/');
    const secondPath = path.substring(1);
    const isPath = path ? !!matchPath({ path, end: false }, pathname) : false;
    if (isPath) {
      return isPath;
    }
    if (firstPath === secondPath) {
      return true;
    }
    return isPath;
  };

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} active={active} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item, active }) {
  const { title, path, icon, info, children } = item;

  const isActiveRoot = active(path);
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  if (children) {
    return (
      <>
        <StyledNavItem
          onClick={handleOpen}
          sx={{
            '&.active': {
              color: 'text.primary',
              bgcolor: 'action.selected',
              fontWeight: 'fontWeightBold',
            },
          }}
        >
          <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

          <ListItemText disableTypography primary={title} />

          {info && info}
          <Icon icon={open ? 'mdi:chevron-up' : 'mdi:chevron-right'} style={{ width: 16, height: 16, ml: 1 }} />
        </StyledNavItem>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((childItem) => (
              <StyledNavItem
                key={childItem.title}
                component={RouterLink}
                to={childItem.path}
                sx={{
                  '&.active': {
                    color: 'text.primary',
                    bgcolor: 'action.selected',
                    fontWeight: 'fontWeightBold',
                  },
                  pl: 4, // Padding left to indent the sub-item
                }}
              >
                <StyledNavItemIcon>{childItem.icon && childItem.icon}</StyledNavItemIcon>

                <ListItemText disableTypography primary={childItem.title} />
              </StyledNavItem>
            ))}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
