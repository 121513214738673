import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { SlCloudUpload } from 'react-icons/sl';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';
import {
  createCategory,
  createDoorList,
  createQuestion,
  getBnbList,
  updateQuestion,
  uploadFile,
} from '../../helper/backend_helper';
import appConfig from '../../config';

const UpdateFAQs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = location?.state?.userData;
  const [formData, setFormData] = useState({ profileImage: null });
  const [answer, setAnswer] = useState(userData?.answer);
  const [bnb, setBnB] = useState(userData?.bnb);
  const [question, setQuestion] = useState(userData?.question);
  const [isChecked, setIsChecked] = useState(userData?.allBnBs);
  const [image,setImage] = useState(userData?.image)
  const [italianQuestion, setItalianQuestion] = useState(userData?.italianQuestion);
  const [italianAnswer, setItalianAnswer] = useState(userData?.italianAnswer);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const [loading, setLoading] = useState(false);
  const handleFileChange = (e) => {
    const { name, files } = e.target;

    setFormData({
      ...formData,
      [name]: files[0],
    });
  };



  const handleDelImage = () => {
    setFormData({
      ...formData,
      profileImage: null,
    });
    setImage(null)
  };

  const [users, setUsers] = useState([]);
  console.log('users', users);
  async function fetchData() {
    try {
      const usersResponse = await getBnbList();
      setUsers(usersResponse?.data?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleSave = async () => {
    try {
      setLoading(true);

      // Check if any field is empty
      // if (!formData.profileImage) {
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'Error',
      //     text: 'Image is required',
      //   });
      //   setLoading(false);
      //   return;
      // }
      if (!question) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Question is required',
        });
        setLoading(false);
        return;
      }

      if (bnb.length===0 && isChecked===false) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'BnB Name is required',
        });
        setLoading(false);
        return;
      }
      if(!italianQuestion){
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Italian Question is required',
        });
        setLoading(false);
        return;
      }
      if(!italianAnswer){
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Italian Answer is required',
        });
        setLoading(false);
        return;
      }

      if (!answer) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Answer is required',
        });
        setLoading(false);
        return;
      }

      // Prepare headers for multipart form data
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      let imageUploadResponse; // Declare it outside the block

      if (formData.profileImage) {
        const data = new FormData();
        data.append('file', formData.profileImage);

        imageUploadResponse = await uploadFile(data, headers); // Assign value inside the block
        console.log(imageUploadResponse?.data?.data?.fileName);

        if (imageUploadResponse.status !== 200) {
          throw new Error('Image upload failed');
        }
      }

      const Categorydata = {
        bnb,
        answer,
        question,
        image: imageUploadResponse?.data?.data?.fileName || image,
        allBnBs: isChecked,
        italianQuestion,
        italianAnswer
      };

      const Categoryheaders = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await updateQuestion(userData?._id, Categorydata, Categoryheaders);

      // setFormData({
      //   ...formData,
      //   profileImage: null,
      // });

      // Show success message
      Swal.fire({
        icon: 'success',
        title: 'FAQ updated successfully',
      });
      // setQuestion('');
      // setBnB('');
      // setAnswer('');

      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `${error?.response?.data?.errors[0]?.message}`,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title> FAQs </title>
      </Helmet>
      <Card sx={{ padding: 3 }}>
        <Stack
          onClick={() => navigate(-1)}
          gap={3}
          sx={{ cursor: 'pointer', mb: 3 }}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <FaArrowLeftLong size={24} />
          <Typography variant="h4">Update FAQ</Typography>
        </Stack>

        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { width: '50ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <Stack>
            <FormControl sx={{ width: 490 }}>
              <FormLabel sx={{ mb: 1 }}>BnB</FormLabel>
              <Select
                  disabled={isChecked}
                labelId="demo-multi-select-label"
                id="demo-multi-select"
                multiple
                value={bnb} // This will be an array
                onChange={(e) => setBnB(e.target.value)}
                renderValue={(selected) => selected.join(', ')} // Render selected values as a comma-separated string
              >
                {users?.map((user) => (
                  <MenuItem key={user._id} value={user.BnBShortName}>
                    <Checkbox checked={bnb.indexOf(user.BnBShortName) > -1} />
                    {user.BnBShortName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack sx={{ marginTop: 2, display: 'flex', flexDirection: 'row', gap: 1 }}>
            <Typography variant="h6" mt={1}>
              All BnBs:
            </Typography>
            <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
          </Stack>
          <Stack sx={{ marginTop: 2 }}>
            <FormLabel sx={{ mb: 1 }}>Question</FormLabel>
            <TextField
              label="Enter Question"
              id="outlined-size-small"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
          </Stack>

          <Stack sx={{ marginTop: 2 }}>
            <FormLabel sx={{ mb: 1 }}>Italian Question</FormLabel>
            <TextField
              label="Enter Italian Question"
              id="outlined-size-small"
              value={italianQuestion}
              onChange={(e) => setItalianQuestion(e.target.value)}
            />
          </Stack>

          <Stack sx={{ marginTop: 2 }}>
            <FormLabel sx={{ mb: 1 }}>Answer</FormLabel>
            <TextField
              label="Enter Answer"
              id="outlined-multiline"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              multiline
              minRows={5}
              variant="outlined"
            />
          </Stack>
          <Stack sx={{ marginTop: 2 }}>
            <FormLabel sx={{ mb: 1 }}>Italian Answer</FormLabel>
            <TextField
              label="Enter Italian Answer"
              id="outlined-multiline"
              value={italianAnswer}
              onChange={(e) => setItalianAnswer(e.target.value)}
              multiline
              minRows={5}
              variant="outlined"
            />
          </Stack>
          <Stack
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 2,
              marginTop: 2,
            }}
          >
            <Stack>
              {!formData.profileImage ? (
                <img
                  src={`${appConfig.FILE_URL}${image}`}
                  alt="img"
                  style={{ maxWidth: '300px', borderRadius: 10 }}
                />
              ) : (
                <img
                  src={URL.createObjectURL(formData.profileImage)}
                  alt="img"
                  style={{ maxWidth: '300px', borderRadius: 10 }}
                />
              )}
            </Stack>
            <Button onClick={handleDelImage} sx={{ width: 130, color: 'red' }}>
              Delete
            </Button>
            <Button
              sx={{
                width: 170,
                color: '#3A5239',
                borderColor: '#3A5239',
                '&:hover': {
                  borderColor: '#3A5239',
                  boxShadow: 'none',
                },
              }}
              component="label"
              variant="outlined"
              size="medium"
              startIcon={<SlCloudUpload />}
            >
              upload Image
              <input
                type="file"
                accept="image/*"
                name="profileImage"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            </Button>

          </Stack>
        </Box>
        <LoadingButton
          sx={{
            width: 100,
            mt: 4,
            mb: 4,
            boxShadow: 'none',
            backgroundColor: '#3A5239',
            '&:hover': {
              backgroundColor: '#3A5239',
              boxShadow: 'none',
            },
          }}
          variant="contained"
          onClick={handleSave}
          loading={loading}
        >
          Update
        </LoadingButton>
      </Card>
    </>
  );
};

export default UpdateFAQs;
