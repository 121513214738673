import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { SlCloudUpload } from 'react-icons/sl';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';
import {
  createCategory,
  createDoorList,
  createSpecialAccesss,
  getBnbList,
  uploadFile,
  createCity,
} from '../../helper/backend_helper';

const AddCity = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ profileImage: null });
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [italiandescription, setitaliandescription] = useState('');

  const [loading, setLoading] = useState(false);
  const handleFileChange = (e) => {
    const { name, files } = e.target;

    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const [users, setUsers] = useState([]);
  console.log('users', users);
  async function fetchData() {
    try {
      const usersResponse = await getBnbList();
      setUsers(usersResponse?.data?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleSave = async () => {
    try {
      setLoading(true);

      if (!name) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Name is required',
        });
        setLoading(false);
        return;
      }
      
      if (!description) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Description is required',
        });
        setLoading(false);
        return;
      }
      
      if (!italiandescription) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Italian Description is required',
        });
        setLoading(false);
        return;
      }
      if (!formData.profileImage) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Image is required',
        });
        setLoading(false);
        return;
      }
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const data = new FormData();
      data.append('file', formData.profileImage);

      const imageUploadResponse = await uploadFile(data, headers);

      if (imageUploadResponse.status !== 200) {
        throw new Error('Image upload failed');
      }
      const Categorydata = {
        name,
        image: imageUploadResponse?.data?.data?.fileName,
        description,
        italiandescription
      };

      const Categoryheaders = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await createCity(Categorydata, Categoryheaders);

      Swal.fire({
        icon: 'success',
        title: 'City created successfully',
      });
      setName('');
      setDescription('');
      setitaliandescription('');
      setFormData({ profileImage: null });

      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `${error?.response?.data?.errors[0]?.message}`,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title> Add City </title>
      </Helmet>
      <Card sx={{ padding: 3 }}>
        <Stack
          onClick={() => navigate(-1)}
          gap={3}
          sx={{ cursor: 'pointer', mb: 3 }}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <FaArrowLeftLong size={24} />
          <Typography variant="h4">Add City</Typography>
        </Stack>

        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { width: '50ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <Stack spacing={{ xs: 2, sm: 4 }} direction="row" useFlexGap flexWrap="wrap">
            <Stack>
              <FormLabel sx={{ mb: 1 }}>Name</FormLabel>
              <TextField
                label="Enter Name"
                id="outlined-size-small"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Stack>
          </Stack>

          <Stack spacing={{ xs: 2, sm: 4 }} direction="row" useFlexGap flexWrap="wrap">
            <Stack sx={{ marginTop: 2 }}>
              <FormLabel sx={{ mb: 1 }}>Description</FormLabel>
              <TextField
                label="Enter Description"
                id="outlined-multiline"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                multiline
                minRows={5}
                variant="outlined"
              />
            </Stack>
          </Stack>
          <Stack spacing={{ xs: 2, sm: 4 }} direction="row" useFlexGap flexWrap="wrap">
            <Stack sx={{ marginTop: 2 }}>
              <FormLabel sx={{ mb: 1 }}>Italian Description</FormLabel>
              <TextField
                label="Enter Italian Description"
                id="outlined-multiline"
                value={italiandescription}
                onChange={(e) => setitaliandescription(e.target.value)}
                multiline
                minRows={5}
                variant="outlined"
              />
            </Stack>
          </Stack>

          <Stack
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 2,
              marginTop: 2,
            }}
          >
            <Stack>
              {formData.profileImage && (
                <img
                  src={URL.createObjectURL(formData.profileImage)}
                  alt="img"
                  style={{ maxWidth: '300px', borderRadius: 10 }}
                />
              )}
            </Stack>
            <Button
              sx={{
                width: 170,
                color: '#3A5239',
                borderColor: '#3A5239',
                '&:hover': {
                  borderColor: '#3A5239',
                  boxShadow: 'none',
                },
              }}
              component="label"
              variant="outlined"
              size="medium"
              startIcon={<SlCloudUpload />}
            >
              upload Image
              <input
                type="file"
                accept="image/*"
                name="profileImage"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            </Button>
          </Stack>


        </Box>
        <LoadingButton
          sx={{
            width: 100,
            mt: 4,
            mb: 4,
            boxShadow: 'none',
            backgroundColor: '#3A5239',
            '&:hover': {
              backgroundColor: '#3A5239',
              boxShadow: 'none',
            },
          }}
          variant="contained"
          onClick={handleSave}
          loading={loading}
        >
          Save
        </LoadingButton>
      </Card>
    </>
  );
};

export default AddCity;
