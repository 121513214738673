import {
    Box,
    Button,
    Card,
    Checkbox,
    Container,
    FormControl,
    FormLabel,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
  } from '@mui/material';
  import React, { useEffect, useState } from 'react';
  import { Helmet } from 'react-helmet-async';
  import { useLocation, useNavigate } from 'react-router-dom';
  import { FaArrowLeftLong } from 'react-icons/fa6';
  import { SlCloudUpload } from 'react-icons/sl';
  import Swal from 'sweetalert2';
  import { LoadingButton } from '@mui/lab';
  import {
    createCategory,
    createDoorList,
    createQuestion,
    getBnbList,
    updateQuestion,
    updateTexi,
  } from '../../helper/backend_helper';
  import appConfig from '../../config';
  
  const UpdateTexi = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const userData = location?.state?.userData;
 
    const [bnb, setBnB] = useState(userData?.bnb);
    const [link, setLink] = useState(userData?.link);
   
  
  
    const [loading, setLoading] = useState(false);

  
  
  
 
  
    const [users, setUsers] = useState([]);
    console.log('users', users);
    async function fetchData() {
      try {
        const usersResponse = await getBnbList();
        setUsers(usersResponse?.data?.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  
    useEffect(() => {
      fetchData();
    }, []);
  
    const handleSave = async () => {
      try {
        setLoading(true);
  
        // Check if any field is empty
        // if (!formData.profileImage) {
        //   Swal.fire({
        //     icon: 'error',
        //     title: 'Error',
        //     text: 'Image is required',
        //   });
        //   setLoading(false);
        //   return;
        // }
        if (!link) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Link is required',
          });
          setLoading(false);
          return;
        }
  
        if (bnb.length===0) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'BnB Name is required',
          });
          setLoading(false);
          return;
        }
     
  
        const Categorydata = {
          bnb,
          link
        };
  
        const Categoryheaders = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
  
        const response = await updateTexi(userData?._id, Categorydata, Categoryheaders);
  
        // setFormData({
        //   ...formData,
        //   profileImage: null,
        // });
  
        // Show success message
        Swal.fire({
          icon: 'success',
          title: 'Texi updated successfully',
        });
        // setQuestion('');
        // setBnB('');
        // setAnswer('');
  
        setLoading(false);
      } catch (error) {
        console.error('Error:', error);
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `${error?.response?.data?.errors[0]?.message}`,
        });
      }
    };
  
    return (
      <>
        <Helmet>
          <title> Taxi </title>
        </Helmet>
        <Card sx={{ padding: 3 }}>
          <Stack
            onClick={() => navigate(-1)}
            gap={3}
            sx={{ cursor: 'pointer', mb: 3 }}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <FaArrowLeftLong size={24} />
            <Typography variant="h4">Update Taxi</Typography>
          </Stack>
  
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { width: '50ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <Stack>
              <FormControl sx={{ width: 490 }}>
                <FormLabel sx={{ mb: 1 }}>BnB</FormLabel>
                <Select
                  labelId="demo-multi-select-label"
                  id="demo-multi-select"
                  multiple
                  value={bnb} // This will be an array
                  onChange={(e) => setBnB(e.target.value)}
                  renderValue={(selected) => selected.join(', ')} // Render selected values as a comma-separated string
                >
                  {users?.map((user) => (
                    <MenuItem key={user._id} value={user.BnBShortName}>
                      <Checkbox checked={bnb.indexOf(user.BnBShortName) > -1} />
                      {user.BnBShortName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
           
            <Stack sx={{ marginTop: 2 }}>
              <FormLabel sx={{ mb: 1 }}>Link</FormLabel>
              <TextField
                label="Enter Link"
                id="outlined-size-small"
                value={link}
                onChange={(e) => setLink(e.target.value)}
              />
            </Stack>
  
           
          </Box>
          <LoadingButton
            sx={{
              width: 100,
              mt: 4,
              mb: 4,
              boxShadow: 'none',
              backgroundColor: '#3A5239',
              '&:hover': {
                backgroundColor: '#3A5239',
                boxShadow: 'none',
              },
            }}
            variant="contained"
            onClick={handleSave}
            loading={loading}
          >
            Update
          </LoadingButton>
        </Card>
      </>
    );
  };
  
  export default UpdateTexi;
  