import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { SlCloudUpload } from 'react-icons/sl';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';
import {
  createCategory,
  createDoorList,
  createSpecialAccesss,
  createAssignCity,
  getAllCity,
  createCity,
  getBnBCities
} from '../../helper/backend_helper';

const AddAssignCity = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ profileImage: null });
  const [name, setName] = useState('');
  const [cities, setCities] = useState([]);
  const [bnbcities, setBnbCities] = useState([]);

  const [loading, setLoading] = useState(false);
  const handleFileChange = (e) => {
    const { name, files } = e.target;

    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const [users, setUsers] = useState([]);
  console.log('users', users);
  async function fetchData() {
    try {
      const usersResponse = await getAllCity();
      setUsers(usersResponse?.data?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  async function fetchBnBCities() {
    try {
      const usersResponse = await getBnBCities();
      setBnbCities(usersResponse?.data?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  useEffect(() => {
    fetchData();
    fetchBnBCities()
  }, []);

  const handleSave = async () => {
    try {
      setLoading(true);

      if (!name) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text:  'BnB Location is required',
        });
        setLoading(false);
        return;
      }

      if (cities.length === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Cities is required',
        });
        setLoading(false);
        return;
      }

      // Prepare headers for multipart form data

      const Categorydata = {
        cityName: name.trim(),
        cities,
      };

      const Categoryheaders = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await createAssignCity(Categorydata, Categoryheaders);

      Swal.fire({
        icon: 'success',
        title: 'City Assign successfully',
      });
      setName('');
      setCities([]);


      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `${error?.response?.data?.errors[0]?.message}`,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title> Assign City </title>
      </Helmet>
      <Card sx={{ padding: 3 }}>
        <Stack
          onClick={() => navigate(-1)}
          gap={3}
          sx={{ cursor: 'pointer', mb: 3 }}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <FaArrowLeftLong size={24} />
          <Typography variant="h4">Assign City</Typography>
        </Stack>

        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { width: '50ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <Stack spacing={{ xs: 2, sm: 4 }} direction="row" useFlexGap flexWrap="wrap">
          <Stack>
              <FormControl sx={{ width: 490 }}>
                <FormLabel sx={{ mb: 1 }}>BnB Location</FormLabel>
                {/* <InputLabel id="demo-simple-select-label">BnB</InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={name}
                  // label="BnB"
                  onChange={(e) => setName(e.target.value)}
                >
                  {bnbcities?.map((user) => (
                    <MenuItem key={user._id} value={user.name}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Stack>

          <Stack spacing={{ xs: 2, sm: 4 }} mt={2} direction="row" useFlexGap flexWrap="wrap">
            <FormControl sx={{ width: 490 }}>
              <FormLabel sx={{ mb: 1 }}>Cities</FormLabel>
              <Select
                labelId="demo-multi-select-label"
                id="demo-multi-select"
                multiple
                value={cities} 
                onChange={(e) => setCities(e.target.value)}
                renderValue={(selected) => selected.join(', ')} 
              >
                {users?.map((user) => (
                  <MenuItem key={user._id} value={user.name}>
                    <Checkbox checked={cities.indexOf(user.name) > -1} />
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Box>
        <LoadingButton
          sx={{
            width: 100,
            mt: 4,
            mb: 4,
            boxShadow: 'none',
            backgroundColor: '#3A5239',
            '&:hover': {
              backgroundColor: '#3A5239',
              boxShadow: 'none',
            },
          }}
          variant="contained"
          onClick={handleSave}
          loading={loading}
        >
          Save
        </LoadingButton>
      </Card>
    </>
  );
};

export default AddAssignCity;
