import { Box, Button, Card, Container, FormLabel, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { SlCloudUpload } from 'react-icons/sl';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';

import {
  createBanner,
  createContactInfo,
  deleteUploads,
  getBanner,
  getContactInfo,
  uploadFile,
} from '../helper/backend_helper';
import appConfig from '../config';
// import logo from 'src/components/logo';

const Banner = () => {
  const [bannerData, setBannerData] = useState({});

  const [formData, setFormData] = useState({ profileImage: null });
  const [formDataF, setFormDataF] = useState({ profileImageF: null });
  const [title, setTitle] = useState('');
  const [whatsApp, setWhatsApp] = useState('');
  const [nonWhatsApp, setNonWhatsApp] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [uiColor, setUiColor] = useState('');
  const [chatBotApiKey, setChatBotApiKey] = useState('');
  const [logoImg, setLogoImg] = useState('');
  const [faviconImg, setFaviconImg] = useState('');
  const [whatsAppMessageEnglish, setWhatsAppMessageEnglish] = useState('');
  const [whatsAppMessageItalian, setWhatsAppMessageItalian] = useState('');
  console.log(startTime);

  const [loading, setLoading] = useState(false);
  const handleFileChange = (e) => {
    const { name, files } = e.target;

    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const handleFileChangeF = (e) => {
    const { name, files } = e.target;

    setFormDataF({
      ...formDataF,
      [name]: files[0],
    });
  };

  const Banner = async () => {
    try {
      const bannerResponse = await getContactInfo();
      setBannerData(bannerResponse?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    Banner();
  }, []);
  useEffect(() => {
    setTitle(bannerData?.name || '');
    setWhatsApp(bannerData?.whatsappNumber || '');
    setNonWhatsApp(bannerData?.nonWhatsappNumber || '');
    setStartTime(bannerData?.startTime || '');
    setEndTime(bannerData?.endTime || '');
    setUiColor(bannerData?.uiColor || '');
    setLogoImg(bannerData?.logo || '');
    setFaviconImg(bannerData?.favicon || '');
    setChatBotApiKey(bannerData?.chatBotApiKey || '');
    setWhatsAppMessageEnglish(bannerData?.whatsAppMessageEnglish || '');
    setWhatsAppMessageItalian(bannerData?.whatsAppMessageItalian || '');
  }, [bannerData]);

  const handleSave = async () => {
    try {
      setLoading(true);

      // Check if any field is empty
      if (title.trim() === '') {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Name cannot be empty',
        });
        setLoading(false);
        return;
      }
      if (whatsApp.trim() === '') {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Whatsapp cannot be empty',
        });
        setLoading(false);
        return;
      }
      if (nonWhatsApp.trim() === '') {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'NonWhatsapp cannot be empty',
        });
        setLoading(false);
        return;
      }
      if (startTime.trim() === '') {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Start Time cannot be empty',
        });
        setLoading(false);
        return;
      }
      if (endTime.trim() === '') {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'End Time cannot be empty',
        });
        setLoading(false);
        return;
      }
      if (!whatsAppMessageEnglish) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Whatsapp Message English cannot be empty',
        });
        setLoading(false);
        return;
      }
      if (!whatsAppMessageItalian) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Whatsapp Message Italian cannot be empty',
        });
        setLoading(false);
        return;
      }

      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      let imageUploadResponseLogo; // Declare it outside the block

      if (formData.profileImage) {
        const data = new FormData();
        data.append('file', formData.profileImage);

        imageUploadResponseLogo = await uploadFile(data, headers); // Assign value inside the block
        console.log(imageUploadResponseLogo?.data?.data?.fileName);

        if (imageUploadResponseLogo.status !== 200) {
          throw new Error('Image upload failed');
        }
      }

      let imageUploadResponseFavicon; // Declare it outside the block

      if (formDataF.profileImageF) {
        const data = new FormData();
        data.append('file', formDataF.profileImageF);

        imageUploadResponseFavicon = await uploadFile(data, headers); // Assign value inside the block
        console.log(imageUploadResponseFavicon?.data?.data?.fileName);

        if (imageUploadResponseFavicon.status !== 200) {
          throw new Error('Image upload failed');
        }
      }

      const BannerData = {
        name: title,
        whatsappNumber: whatsApp,
        nonWhatsappNumber: nonWhatsApp,
        startTime,
        endTime,
        uiColor,
        favicon: imageUploadResponseFavicon?.data?.data?.fileName || bannerData?.favicon,
        logo: imageUploadResponseLogo?.data?.data?.fileName || bannerData?.logo,
        chatBotApiKey: chatBotApiKey.trim(),
        whatsAppMessageEnglish,
        whatsAppMessageItalian,
      };

      const Categoryheaders = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await createContactInfo(BannerData, Categoryheaders);

      // setTitle('');
      // setFormData({
      //   ...formData,
      //   profileImage: null,
      // });

      // Show success message
      Swal.fire({
        icon: 'success',
        title: 'WebApp Settings updated successfully',
      });

      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error while updating Web App Settings',
      });
    }
  };

  return (
    <>
      <Helmet>
        <title> WebApp Settings </title>
      </Helmet>
      <Card sx={{ padding: 3 }}>
        <Stack
          // onClick={() => navigate(-1)}
          gap={3}
          sx={{ cursor: 'pointer', mb: 3 }}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Typography variant="h4">WebApp Settings</Typography>
        </Stack>

        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { width: '50ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <Stack spacing={{ xs: 2, sm: 4 }} direction="row" useFlexGap flexWrap="wrap">
            <Stack>
              <FormLabel sx={{ mb: 1 }}>Name</FormLabel>
              <TextField
                label="Enter Name"
                id="outlined-size-small"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Stack>
          </Stack>
          <Stack spacing={{ xs: 2, sm: 4 }} mt={4} direction="row" useFlexGap flexWrap="wrap">
            <Stack>
              <FormLabel sx={{ mb: 1 }}>WhatsApp Number</FormLabel>
              <TextField
                type="number"
                label="Enter Whatsapp number"
                id="outlined-size-small"
                value={whatsApp}
                onChange={(e) => setWhatsApp(e.target.value)}
              />
            </Stack>
          </Stack>
          <Stack spacing={{ xs: 2, sm: 4 }} mt={4} direction="row" useFlexGap flexWrap="wrap">
            <Stack>
              <FormLabel sx={{ mb: 1 }}>Non Whatsapp Number</FormLabel>
              <TextField
                type="number"
                label="Enter Non whatsapp number"
                id="outlined-size-small"
                value={nonWhatsApp}
                onChange={(e) => setNonWhatsApp(e.target.value)}
              />
            </Stack>
          </Stack>
          <Stack spacing={{ xs: 2, sm: 4 }} mt={4} direction="row" useFlexGap flexWrap="wrap">
            <Stack>
              <FormLabel sx={{ mb: 1 }}>UI Main Color</FormLabel>
              <TextField
                label="Enter color code e.g #ff0000"
                id="outlined-size-small"
                inputProps={{
                  maxLength: 7,
                }}
                value={uiColor}
                onChange={(e) => setUiColor(e.target.value)}
              />
            </Stack>
          </Stack>

          <Stack spacing={{ xs: 2, sm: 4 }} mt={4} direction="row" useFlexGap flexWrap="wrap">
            <Stack>
              <FormLabel sx={{ mb: 1 }}>Default Checkin Time</FormLabel>
              <TextField
                label="Default Checkin Time"
                id="outlined-size-small"
                type="time" // Ensures 24-hour format is used
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                InputLabelProps={{ shrink: true }} // Keeps label visible
                inputProps={{ step: 300 }} // Optional: 5-minute steps (adjustable)
              />
            </Stack>
          </Stack>

          <Stack spacing={{ xs: 2, sm: 4 }} mt={4} direction="row" useFlexGap flexWrap="wrap">
            <Stack>
              <FormLabel sx={{ mb: 1 }}>Default Checkout Time</FormLabel>
              <TextField
                label="Default Checkout Time"
                id="outlined-size-small"
                type="time" // Ensures 24-hour format is used
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                InputLabelProps={{ shrink: true }} // Keeps label visible
                inputProps={{ step: 300 }} // Optional: 5-minute steps (adjustable)
              />
            </Stack>
          </Stack>
          <Stack spacing={{ xs: 2, sm: 4 }} mt={4} direction="row" useFlexGap flexWrap="wrap">
            <Stack>
              <FormLabel sx={{ mb: 1 }}>Chat Bot Api Key</FormLabel>
              <TextField
                label="Enter Chat Bot Api Key"
                id="outlined-size-small"
                value={chatBotApiKey}
                onChange={(e) => setChatBotApiKey(e.target.value)}
              />
            </Stack>
          </Stack>
          <Stack spacing={{ xs: 2, sm: 4 }} mt={4} direction="row" useFlexGap flexWrap="wrap">
            <Stack>
              <FormLabel sx={{ mb: 1 }}>English Message</FormLabel>
              <TextField
                multiline
                minRows={4}
                label="Enter English Message"
                id="outlined-size-small"
                value={whatsAppMessageEnglish}
                onChange={(e) => setWhatsAppMessageEnglish(e.target.value)}
              />
            </Stack>
          </Stack>
          <Stack spacing={{ xs: 2, sm: 4 }} mt={4} direction="row" useFlexGap flexWrap="wrap">
            <Stack>
              <FormLabel sx={{ mb: 1 }}>Italian Message</FormLabel>
              <TextField
                multiline
                minRows={4}
                label="Enter Italian Message"
                id="outlined-size-small"
                value={whatsAppMessageItalian}
                onChange={(e) => setWhatsAppMessageItalian(e.target.value)}
              />
            </Stack>
          </Stack>
          <Stack
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 2,
              marginTop: 2,
            }}
          >
            <Stack>
              {!formData.profileImage ? (
                <img
                  src={`${appConfig.FILE_URL}${logoImg}`}
                  alt="img"
                  style={{ maxWidth: '300px', borderRadius: 10 }}
                />
              ) : (
                <img
                  src={URL.createObjectURL(formData.profileImage)}
                  alt="img"
                  style={{ maxWidth: '300px', borderRadius: 10 }}
                />
              )}
            </Stack>
            <Button
              sx={{
                width: 170,
                color: '#3A5239',
                borderColor: '#3A5239',
                '&:hover': {
                  borderColor: '#3A5239',
                  boxShadow: 'none',
                },
              }}
              component="label"
              variant="outlined"
              size="medium"
              startIcon={<SlCloudUpload />}
            >
              upload Logo
              <input
                type="file"
                accept="image/*"
                name="profileImage"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            </Button>
          </Stack>

          <Stack
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 2,
              marginTop: 2,
            }}
          >
            <Stack>
              {!formDataF.profileImageF ? (
                <img
                  src={`${appConfig.FILE_URL}${faviconImg}`}
                  alt="img"
                  style={{ maxWidth: '300px', borderRadius: 10 }}
                />
              ) : (
                <img
                  src={URL.createObjectURL(formDataF.profileImageF)}
                  alt="img"
                  style={{ maxWidth: '300px', borderRadius: 10 }}
                />
              )}
            </Stack>
            <Button
              sx={{
                width: 170,
                color: '#3A5239',
                borderColor: '#3A5239',
                '&:hover': {
                  borderColor: '#3A5239',
                  boxShadow: 'none',
                },
              }}
              component="label"
              variant="outlined"
              size="medium"
              startIcon={<SlCloudUpload />}
            >
              upload Favicon
              <input
                type="file"
                accept="image/*"
                name="profileImageF"
                style={{ display: 'none' }}
                onChange={handleFileChangeF}
              />
            </Button>
          </Stack>
        </Box>
        <LoadingButton
          sx={{
            // width: 150,
            mt: 4,
            mb: 4,
            boxShadow: 'none',
            backgroundColor: '#3A5239',
            '&:hover': {
              backgroundColor: '#3A5239',
              boxShadow: 'none',
            },
          }}
          variant="contained"
          onClick={handleSave}
          loading={loading}
        >
          Update
        </LoadingButton>
      </Card>
    </>
  );
};

export default Banner;
