import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { SlCloudUpload } from 'react-icons/sl';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';
import { createCategory, createDoorList, getBnbList, updateDoorlist, uploadFile } from '../../helper/backend_helper';
import appConfig from '../../config';

const UpdateCategory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = location?.state?.userData;



  const [formData, setFormData] = useState({ profileImage: null });
  const [doorName, setDoorName] = useState(userData?.doorName);
  const [bnb, setBnB] = useState(userData?.bnb);
  const [script, setScript] = useState(userData?.scriptOrCode);
  const [value, setValue] = useState(userData?.value);
  const [deviceId, setDeviceId] = useState(userData?.deviceId);
  const [description, setDescription] = useState(userData?.description);
  const [number, setNumber] = useState(userData?.number);
  const [loading, setLoading] = useState(false);
  const [italiandoorName, setItalianDoorName] = useState(userData?.italiandoorName);
  const [italianDescription, setItalianDescription] = useState(userData?.italianDescription);
  const handleFileChange = (e) => {
    const { name, files } = e.target;

    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const [users, setUsers] = useState([]);
  console.log('users', users);
  async function fetchData() {
    try {
      const usersResponse = await getBnbList();
      setUsers(usersResponse?.data?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleSave = async () => {
    try {
      setLoading(true);
      if (!doorName) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Door Name is required',
        });
        setLoading(false);
        return;
      }

      if (!bnb) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'BnB Name is required',
        });
        setLoading(false);
        return;
      }

      if (!script) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Script is required',
        });
        setLoading(false);
        return;
      }

      if (!value) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Value is required',
        });
        setLoading(false);
        return;
      }

      if (!description) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Description is required',
        });
        setLoading(false);
        return;
      }
      if(!number){
        Swal.fire({
          icon: 'error',  
          title: 'Error',
          text: 'Number is required',
        });
        setLoading(false);
        return;
      }
      if(!italiandoorName){
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Italian Door Name is required',
        });
        setLoading(false);
        return;
      }

      if(!italianDescription){
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Italian Description is required',
        });
        setLoading(false);
        return;
      }
      // Prepare headers for multipart form data
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      let imageUploadResponse;  // Declare it outside the block

      if (formData.profileImage) {
        const data = new FormData();
        data.append('file', formData.profileImage);
      
        imageUploadResponse = await uploadFile(data, headers);  // Assign value inside the block
        console.log(imageUploadResponse?.data?.data?.fileName);
      
        if (imageUploadResponse.status !== 200) {
          throw new Error('Image upload failed');
        }
      }
      
      // Build the Categorydata object
      const Categorydata = {
        photo: imageUploadResponse?.data?.data?.fileName || userData?.photo, // Use the uploaded image or fallback
        description,
        scriptOrCode: script,
        doorName,
        bnb,
        value,
        number,
        deviceId,
        italiandoorName,
        italianDescription
      };
      

      const Categoryheaders = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await updateDoorlist(userData?._id,Categorydata, Categoryheaders);

      

      // Show success message
      Swal.fire({
        icon: 'success',
        title: 'Door List updated successfully',
      });
      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `${error?.response?.data?.errors[0]?.message}`,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title> door list </title>
      </Helmet>
      <Card sx={{ padding: 3 }}>
        <Stack
          onClick={() => navigate(-1)}
          gap={3}
          sx={{ cursor: 'pointer', mb: 3 }}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <FaArrowLeftLong size={24} />
          <Typography variant="h4">Update Door List</Typography>
        </Stack>

        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { width: '50ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <Stack spacing={{ xs: 2, sm: 4 }} direction="row" useFlexGap flexWrap="wrap">
            <Stack>
              <FormLabel sx={{ mb: 1 }}>Boor Name</FormLabel>
              <TextField
                label="Enter Door Name"
                id="outlined-size-small"
                value={doorName}
                onChange={(e) => setDoorName(e.target.value)}
              />
            </Stack>
            <Stack>
              <FormLabel sx={{ mb: 1 }}>Italian Door Name</FormLabel>
              <TextField
                label="Enter Italian Door Name"
                id="outlined-size-small"
                value={italiandoorName}
                onChange={(e) => setItalianDoorName(e.target.value)}
              />
            </Stack>
            {/* <Stack>
              <FormLabel sx={{ mb: 1 }}>Script</FormLabel>
              <TextField
                label="Enter Script"
                id="outlined-size-small"
                value={script}
                onChange={(e) => setScript(e.target.value)}
              />
            </Stack> */}

            <Stack>
              <FormControl sx={{ width: 490 }}>
                <FormLabel sx={{ mb: 1 }}>Script</FormLabel>
                {/* <InputLabel id="demo-simple-select-label">BnB</InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={script}
                  // label="BnB"
                  onChange={(e) => setScript(e.target.value)}
                >
                  <MenuItem value="Script">Script</MenuItem>
                  <MenuItem value="Lockbox">Lockbox</MenuItem>
                  <MenuItem value="Script + Doorbell">Script + Doorbell</MenuItem>
                  {/* {users?.map((user) => (
                    <MenuItem key={user._id} value={user.BnBShortName}>
                      {user.BnBShortName}
                    </MenuItem>
                  ))} */}
                </Select>
              </FormControl>
            </Stack>

            <Stack>
              <FormLabel sx={{ mb: 1 }}>Script value/Keybox code</FormLabel>
              <TextField
          
                label="Enter Script Name"
                id="outlined-size-small"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </Stack>
            <Stack>
              <FormLabel sx={{ mb: 1 }}>Device ID / Phone Number</FormLabel>
              <TextField
      
                label="Enter Device ID / Phone Number"
                id="outlined-size-small"
                value={deviceId}
                onChange={(e) => setDeviceId(e.target.value)}
              />
            </Stack>
            <Stack>
              <FormLabel sx={{ mb: 1 }}>Description</FormLabel>
              <TextField
                  multiline
                  rows={4}
                label="Enter Description"
                id="outlined-size-small"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Stack>

            <Stack>
              <FormLabel sx={{ mb: 1 }}>Italian Description</FormLabel>
              <TextField
                  multiline
                  rows={4}
                label="Enter Italian Description"
                id="outlined-size-small"
                value={italianDescription}
                onChange={(e) => setItalianDescription(e.target.value)}
              />
            </Stack>
            <Stack>
            <FormControl sx={{ width: 490 }}>
              <FormLabel sx={{ mb: 1 }}>BnB</FormLabel>
              <Select
                labelId="demo-multi-select-label"
                id="demo-multi-select"
                multiple
                value={bnb} // This will be an array
                onChange={(e) => setBnB(e.target.value)}
                renderValue={(selected) => selected.join(', ')} // Render selected values as a comma-separated string
              >
                {users?.map((user) => (
                  <MenuItem key={user._id} value={user.BnBShortName}>
                    <Checkbox checked={bnb.indexOf(user.BnBShortName) > -1} />
                    {user.BnBShortName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </Stack>

            <Stack>
              <FormLabel sx={{ mb: 1 }}>Sequence Number</FormLabel>
              <TextField
                type="number"
                label="Enter Sequence Number"
                id="outlined-size-small"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
              />
            </Stack>
          </Stack>

          <Stack
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 2,
              marginTop: 2,
            }}
          >
           <Stack>
                {!formData.profileImage ? (
                  <img
                    src={`${appConfig.FILE_URL}${userData?.photo}`}
                    alt="img"
                    style={{ maxWidth: '300px', borderRadius: 10 }}
                  />
                ) : (
                  <img
                    src={URL.createObjectURL(formData.profileImage)}
                    alt="img"
                    style={{ maxWidth: '300px', borderRadius: 10 }}
                  />
                )}
              </Stack>
            <Button
              sx={{
                width: 170,
                color: '#3A5239',
                borderColor: '#3A5239',
                '&:hover': {
                  borderColor: '#3A5239',
                  boxShadow: 'none',
                },
              }}
              component="label"
              variant="outlined"
              size="medium"
              startIcon={<SlCloudUpload />}
            >
              upload Image
              <input
                type="file"
                accept="image/*"
                name="profileImage"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            </Button>
          </Stack>
        </Box>
        <LoadingButton
          sx={{
            width: 100,
            mt: 4,
            mb: 4,
            boxShadow: 'none',
            backgroundColor: '#3A5239',
            '&:hover': {
              backgroundColor: '#3A5239',
              boxShadow: 'none',
            },
          }}
          variant="contained"
          onClick={handleSave}
          loading={loading}
        >
          Update
        </LoadingButton>
      </Card>
    </>
  );
};

export default UpdateCategory;
