import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { useNavigate, useLocation } from 'react-router-dom';
import { SlCloudUpload } from 'react-icons/sl';
import {
  Box,
  Button,
  Card,
  Container,
  FormLabel,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2';

import axios from 'axios';
import appConfig from '../../config';
import { createUser, updateBnbList, updateUser,uploadFile } from '../../helper/backend_helper';
import Iconify from '../iconify';

const UpdateUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = location?.state?.userData;

  const [showPassword, setShowPassword] = useState(false);
  const [videoData, setVideoData] = useState({ profileVideo: null });
  const [videoLink,setVideoLink] = useState(userData?.videoLink)
  const handleFileChange = (e) => {
    const { name, files } = e.target;

    setVideoData({
      ...videoData,
      [name]: files[0],
    });
  };

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    wifiPassword: userData?.wifiPassword,
    wifiSsid: userData?.wifiSSID,
    contactNo: userData?.contactNumber,
    googleMapLink: userData?.googleMapLink,
    youtubeLink: userData?.utubeLink,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
const handleDelVideo = () => {
  setVideoData({ profileVideo: null });
  setVideoLink("");

}
  const handleSave = async () => {
    try {
      setLoading(true);

      // Check if any field is empty
      if (!formData.wifiPassword || !formData.wifiSsid || !formData.contactNo) {
        Swal.fire({
          icon: 'error',
          title: 'error',
          text: 'Please fill in all fields!',
        });
        setLoading(false);
        return; // Stop execution if any field is empty
      }



      let imageUploadResponse; // Declare it outside the block

      if (videoData.profileVideo) {
        const headers = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
        const data = new FormData();
        data.append('file', videoData.profileVideo);

        imageUploadResponse = await uploadFile(data, headers); // Assign value inside the block
        console.log(imageUploadResponse?.data?.data?.fileName);

        if (imageUploadResponse.status !== 200) {
          throw new Error('Image upload failed');
        }
      }

      const data = {
        contactNumber: formData.contactNo,
        wifiPassword: formData.wifiPassword,
        wifiSSID: formData.wifiSsid,
        googleMapLink: formData.googleMapLink,
        utubeLink: formData.youtubeLink,
        videoLink: imageUploadResponse?.data?.data?.fileName || videoLink,
      };

      const headers = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await updateBnbList(userData._id, data, headers);

      // Show success message
      Swal.fire({
        icon: 'success',
        title: 'BnB List updated successfully',
      });

      setLoading(false);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'error',
        text: `SomeThing went Wrong`,
      });
      setLoading(false);
      // Handle error, maybe show an error message to the user
    }
  };

  return (
    <>
      <Helmet>
        <title>Update bnb </title>
      </Helmet>
      <Card sx={{ padding: 1 }}>
        <Container>
          <Stack
            onClick={() => navigate(-1)}
            gap={3}
            sx={{ cursor: 'pointer', mb: 3 }}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <FaArrowLeftLong size={24} />
            <Typography variant="h4">Update BnB List</Typography>
          </Stack>

          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { width: '40ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <Typography sx={{ mb: 3 }} variant="h5">
              BnB Name: {userData?.BnBLongName}
            </Typography>
            <Stack spacing={{ xs: 2, sm: 4 }} direction="row" useFlexGap flexWrap="wrap">
              <Stack>
                <FormLabel sx={{ mb: 1 }}>Wifi SSID</FormLabel>
                <TextField name="wifiSsid" label="Wifi SSID" value={formData.wifiSsid} onChange={handleInputChange} />
              </Stack>
              <Stack>
                <FormLabel sx={{ mb: 1 }}>Wifi Password</FormLabel>
                <TextField
                  name="wifiPassword"
                  label="Wifi Password"
                  value={formData.wifiPassword}
                  onChange={handleInputChange}
                />
              </Stack>

              <Stack>
                <FormLabel sx={{ mb: 1 }}>Contact No</FormLabel>
                <TextField
                
                  name="contactNo"
                  label="Contact No"
                  value={formData.contactNo}
                  onChange={handleInputChange}
                />
              </Stack>
              <Stack>
                <FormLabel sx={{ mb: 1 }}>Google Map Link</FormLabel>
                <TextField
                  name="googleMapLink"
                  label="Google Map Link"
                  value={formData.googleMapLink}
                  onChange={handleInputChange}
                />
              </Stack>
              <Stack>
                <FormLabel sx={{ mb: 1 }}>Youtube Link</FormLabel>
                <TextField
                  name="youtubeLink"
                  label="Youtube Link"
                  value={formData.youtubeLink}
                  onChange={handleInputChange}
                />
              </Stack>
            </Stack>

            <Stack
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                gap: 2,
                marginTop: 2,
              }}
            >
              <Stack>
                {!videoData.profileVideo ? (
                  <>
                    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                    <video
                      controls
                      src={`${appConfig.FILE_URL}${videoLink}`}
                      style={{ maxWidth: '250px', borderRadius: 20, padding: 5 }}
                    />
                  </>
                ) : (
                  <>
                    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                    <video
                      controls
                      src={URL.createObjectURL(videoData.profileVideo)}
                      style={{ maxWidth: '250px', borderRadius: 20, padding: 5 }}
                    />
                  </>
                )}
              </Stack>
              <Button onClick={handleDelVideo} sx={{ width: 130, color: 'red' }}>
              Delete
            </Button>
              <Button
                sx={{
                  width: 170,
                  color: '#3A5239',
                  borderColor: '#3A5239',
                  '&:hover': {
                    borderColor: '#3A5239',
                    boxShadow: 'none',
                  },
                }}
                component="label"
                variant="outlined"
                size="medium"
                startIcon={<SlCloudUpload />}
              >
                Upload Video
                <input
                  type="file"
                  accept="video/*"
                  name="profileVideo"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
              </Button>
            </Stack>

            <LoadingButton
              sx={{
                width: 150,
                mt: 4,
                mb: 4,
                boxShadow: 'none',
                backgroundColor: '#3A5239',

                '&:hover': {
                  backgroundColor: '#3A5239',
                  boxShadow: 'none',
                },
              }}
              variant="contained"
              onClick={handleSave}
              loading={loading}
            >
              Update
            </LoadingButton>
          </Box>
        </Container>
      </Card>
    </>
  );
};

export default UpdateUser;
