import { APIClient } from './api_helper';
import * as url from './url_helper';

const api = new APIClient();

// Login Method
 export const postJwtLogin = (data, headers) => api.create(url.POST_LOGIN, data, headers);

export const getUsers = () => api.get(url.GET_USERS);
export const getSolutions = () => api.get(url.GET_SOLUTIONS);
export const getAnnouncement = () => api.get(url.GET_ANNOUNCEMENT);

export const createAnnouncement = (data , headers) => api.create(url.GET_ANNOUNCEMENT,data,headers);
export const getCategories = () => api.get(url.GET_CATEGORIES);
// export const createCategory = (data , headers) => api.create(url.GET_CATEGORIES,data,headers);
// export const deleteCategory = (id, headers) =>
//   api.create(`${url.DELETE_CATEGORY}/${id}`, {}, headers);
export const deleteDoor = (id, headers) =>
  api.create(`${url.DEL_DOORLIST}/${id}`, {}, headers);
  // export const updateCategory = (id, data, headers) => {
  //   return api.create(`${url.UPDATE_CATEGORY}/${id}`, data, headers);
  // }
  export const createSolution = (id, data, headers) => {
    return api.create(`${url.CREATE_SOLUTION}/${id}`, data, headers);
  }

export const createUser = (data , headers) => api.create(url.CREATE_USER,data,headers);

export const deleteUser = (id, headers) =>
  api.create(`${url.DELETE_USER}/${id}`, {}, headers);

  export const updateUser = (id, data, headers) => {
    return api.create(`${url.UPDATE_USER}/${id}`, data, headers);
  }

  export const uploadFile = (data , headers) => api.create(url.UPLOAD_FILE,data,headers);
  export const deleteSolution = (id, headers) =>
  api.create(`${url.DELETE_SOLUTION}/${id}`, {}, headers);



  export const updateSolution = (id, data, headers) => {
    return api.update(`${url.CREATE_SOLUTION}/${id}`, data, headers);
  }


  export const getBanner = () => api.get(url.GET_BANNER);
  export const createBanner = (data , headers) => api.create(url.GET_BANNER,data,headers);

  export const deleteUploads = (data , headers) => api.create(url.DELETE_UPLOADS,data,headers);


  // ===================================================================================



  export const getBookings = () => api.get(url.GET_BOOKINGS);
  export const getBnbList = () => api.get(url.GET_BNBLIST);

  export const createBnBList = (data , headers) => api.create(url.GET_BNBLIST,data,headers)


  export const updateBnbList = (id, data, headers) => {
    return api.create(`${url.GET_BNBLIST}/${id}`, data, headers);
  }



  export const getDoorList = () => api.get(url.GET_DOORLIST);

  export const createDoorList = (data , headers) => api.create(url.CREATE_DOORLIST,data,headers)

  export const updateDoorlist = (id, data, headers) => {
    return api.create(`${url.UPDATE_DOORLIST}/${id}`, data, headers);
  }


  export const getContactInfo = () => api.get(url.CONTACTINFO);

  export const createContactInfo = (data , headers) => api.create(url.CONTACTINFO,data,headers)


  export const getAllQuestions = () => api.get(url.QUESTIONS);

  export const createQuestion = (data , headers) => api.create(url.QUESTIONS,data,headers)
  export const deleteQuestion = (id, headers) =>
    api.create(`${url.DEL_QUESTIONS}/${id}`, {}, headers);

  export const updateQuestion = (id, data, headers) => {
    return api.create(`${url.UPDATE_QUESTIONS}/${id}`, data, headers);
  }


  export const getAllAuthToken = () => api.get(url.AUTH_TOKEN);
  export const updateAuthToken = (data , headers) => api.create(url.AUTH_TOKEN,data,headers)


  export const getAllSpecialAcess = () => api.get(url.SPECIAL_ACCESSS);
  export const createSpecialAccesss = (data , headers) => api.create(url.SPECIAL_ACCESSS,data,headers)
  export const deleteSpecialAccess = (id, headers) =>
    api.create(`${url.SPECIAL_ACCESS_DELETE}/${id}`, {}, headers);

  export const updateSpecialAccess = (id, data, headers) => {
    return api.create(`${url.SPECIAL_ACCESS_UPDATE}/${id}`, data, headers);
  }



  export const getAllTravelGuide = () => api.get(url.TRAVEL_GUIDE);
  export const deleteTravelGuide = (id, headers) =>
    api.create(`${url.TRAVEL_GUIDE}/${id}`, {}, headers);

  export const addTravelGuide = (data , headers) => api.create(url.TRAVEL_GUIDE,data,headers)
  export const updateTravelGuide = (id, data, headers) => {
    return api.create(`${url.UPDATE_TRAVEL_GUIDE}/${id}`, data, headers);
  }

  export const getAllCity = () => api.get(url.CITY);
  
  export const deleteCity = (id, headers) =>
    api.create(`${url.DELETE_CITY}/${id}`, {}, headers);


  export const createCity = (data , headers) => api.create(url.CITY,data,headers)

  export const updateCity = (id, data, headers) => {
    return api.create(`${url.UPDATE_CITY}/${id}`, data, headers);
  }



  export const getAllCategory = () => api.get(url.CATEGORY);
  export const createCategory = (data , headers) => api.create(url.CATEGORY,data,headers)
  export const deleteCategory = (id, headers) =>
    api.create(`${url.DELETE_CATEGORY}/${id}`, {}, headers);
  export const updateCategory = (id, data, headers) => {
    return api.create(`${url.UPDATE_CATEGORY}/${id}`, data, headers);
  }


  export const getCategoriesOfCity = (city) => api.get(`${url.GET_CATEGORIES_OF_CITY}/${city}`)




  export const getAllAssignCity = () => api.get(url.AssignCity);
  export const deleteAssignCity = (id, headers) =>
    api.create(`${url.DELETE_ASSIGN_CITY}/${id}`, {}, headers);

  export const createAssignCity = (data , headers) => api.create(url.AssignCity,data,headers)
  export const updateAssignCity = (id, data, headers) => {
    return api.create(`${url.UPDATE_ASSIGN_CITY}/${id}`, data, headers);
  }




  export const refreshCities = () => api.get(url.REFRESH_CITIES);

  export const getBnBCities = () => api.get(url.getBnBCities);

  export const getMaintance = () => api.get(url.CREATE_MAINTANCE);
  export const updateMaintance = (id, headers) =>
    api.create(`${url.UPDATE_MAINTANCE}/${id}`, {}, headers);




  export const getRefillRequest = () => api.get(url.REFILL_REQUEST);
  export const updateRefillRequest = (id, headers) =>
    api.create(`${url.UPDATE_REFILL_REQUEST}/${id}`, {}, headers);



  export const getExtraCleaningRequest = () => api.get(url.EXTRA_CLEANING_REQUEST);
  export const updateExtraCleaningRequest = (id, headers) =>
    api.create(`${url.UPDATE_EXTRA_CLEANING_REQUEST}/${id}`, {}, headers);





  export const getAllTexi = () => api.get(url.TEXI);
  export const createTexi = (data , headers) => api.create(url.TEXI,data,headers)

  export const updateTexi = (id, data, headers) => {
    return api.create(`${url.UPDATE_TEXI}/${id}`, data, headers);
  }

  export const deleteTexi = (id, headers) =>
    api.create(`${url.DELETE_TEXI}/${id}`, {}, headers);




  
  export const getAllAroundUs = () => api.get(url.AROUNDUS);
  export const createAroundUs = (data , headers) => api.create(url.AROUNDUS,data,headers)

  export const updateAroundUs = (id, data, headers) => {
    return api.create(`${url.UPDATE_AROUNDUS}/${id}`, data, headers);
  }

  export const deleteAroundUs = (id, headers) =>
    api.create(`${url.DELETE_AROUNDUS}/${id}`, {}, headers);




  export const getAllRefill = () => api.get(url.REFILL);
  export const createRefill = (data , headers) => api.create(url.REFILL,data,headers)

  export const updateRefill = (id, data, headers) => {
    return api.create(`${url.UPDATE_REFILL}/${id}`, data, headers);
  }

  export const deleteRefill = (id, headers) =>
    api.create(`${url.DELETE_REFILL}/${id}`, {}, headers);






  export const getAllAssignRefill = () => api.get(url.ASSIGN_REFILL);
  export const createAssignRefill = (data , headers) => api.create(url.ASSIGN_REFILL,data,headers)

  export const updateAssignRefill = (id, data, headers) => {
    return api.create(`${url.UPDATE_ASSIGN_REFILL}/${id}`, data, headers);
  }

  export const deleteAssignRefill = (id, headers) =>
    api.create(`${url.DELETE_ASSIGN_REFILL}/${id}`, {}, headers);


  export const getAllCleaningItem = () => api.get(url.CLEANING_ITEMS);
  export const createCleaningItem = (data , headers) => api.create(url.CLEANING_ITEMS,data,headers)

  export const updateCleaningItem = (id, data, headers) => {
    return api.create(`${url.UPDATE_CLEANING_ITEMS}/${id}`, data, headers);
  }

  export const deleteCleaningItem = (id, headers) =>
    api.create(`${url.DELETE_CLEANING_ITEMS}/${id}`, {}, headers);





  export const getAllAssignCleaning = () => api.get(url.ASSIGN_CLEANING);
  export const createAssignCleaning = (data , headers) => api.create(url.ASSIGN_CLEANING,data,headers)

  export const updateAssignCleaning = (id, data, headers) => {
    return api.create(`${url.UPDATE_ASSIGN_CLEANING}/${id}`, data, headers);
  }

  export const deleteAssignCleaning = (id, headers) =>
    api.create(`${url.DELETE_ASSIGN_CLEANING}/${id}`, {}, headers);





  export const getAllUsers = () => api.get(url.GET_ALL_USER);
  export const createUsers = (data , headers) => api.create(url.CREATE_USER,data,headers)

  export const updateUsers = (id, data, headers) => {
    return api.create(`${url.UPDATE_USER}/${id}`, data, headers);
  }

  export const deleteUsers = (id, headers) =>
    api.create(`${url.DELETE_USER}/${id}`, {}, headers);


  export const getAllRoles = () => api.get(url.ROLE);





  
  export const getAllCleaner = () => api.get(url.CLEANER);
  export const createCleaner = (data , headers) => api.create(url.CLEANER,data,headers)
  export const deleteCleaner = (id, headers) =>
    api.create(`${url.CLEANER_DELETE}/${id}`, {}, headers);

  export const updateCleaner = (id, data, headers) => {
    return api.create(`${url.CLEANER_UPDATE}/${id}`, data, headers);
  }



  export const getAllCleaningReport = () => api.get(url.GET_CLEANINGREPORT);

  
  export const deleteCleanerReport = (id, headers) =>
    api.create(`${url.CLEANER_REPORT_DELETE}/${id}`, {}, headers);

  export const deleteRefillRequest = (id, headers) =>
    api.create(`${url.DELETE_REFILL_REQUEST}/${id}`, {}, headers);

  export const deleteExtraCleaningRequest = (id, headers) =>
    api.create(`${url.DELETE_EXTRA_CLEANING_REQUEST}/${id}`, {}, headers);