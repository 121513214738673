import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { SlCloudUpload } from 'react-icons/sl';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';
import {
  addTravelGuide,
  createCategory,
  createDoorList,
  getAllCity,
  getBnbList,
  getCategoriesOfCity,
  updateTravelGuide,
  uploadFile,
} from '../../helper/backend_helper';
import appConfig from '../../config';

const UpdateTravelGuide = () => {
  const navigate = useNavigate();
  const Location = useLocation();
  const userData = Location?.state?.userData;
  const [formData, setFormData] = useState({ profileImage: null });
  console.log('formData', formData.profileImage);

  const [city, setCity] = useState(userData?.city);
  const [category, setCategory] = useState(userData?.category);
  const [name, setName] = useState(userData?.name);
  const [description, setDescription] = useState(userData?.description);
  const [phoneNo, setPhoneNo] = useState(userData?.phoneNo);
  const [address, setAddress] = useState(userData?.address);
  const [location, setLocation] = useState(userData?.location);
  // const [italianName, setItalianName] = useState(userData?.italianName);
  const [italianDescription, setItalianDescription] = useState(userData?.italianDescription);
  // const [italianAddress, setItalianAddress] = useState(userData?.italianAddress);

  const [loading, setLoading] = useState(false);
  const handleFileChange = (e) => {
    const { name, files } = e.target;

    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);

  async function fetchData() {
    try {
      const usersResponse = await getAllCity();
      setCities(usersResponse?.data?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCategoriesOfCity(city);
        setCategories(response?.data?.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (city) {
      fetchData();
    }
  }, [city]);

  const handleChangeCity = async (event) => {
    try {
      const response = await getCategoriesOfCity(event.target.value);
      console.log(response);
      setCategories(response?.data?.data);
      setCity(event.target.value);
      setCategory('');
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);

      if (!city) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'City is required',
        });
        setLoading(false);
        return;
      }

      if (!category) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Category is required',
        });
        setLoading(false);
        return;
      }

      if (!name) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Name is required',
        });
        setLoading(false);
        return;
      }

      if (!description) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Description is required',
        });
        setLoading(false);
        return;
      }

      if (!address) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Address is required',
        });
        setLoading(false);
        return;
      }

      if (!location) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Location is required',
        });
        setLoading(false);
        return;
      }

      // if (!italianName) {
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'Error',
      //     text: 'Italian name is required',
      //   });
      //   setLoading(false);
      //   return;
      // }

      if (!italianDescription) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Italian Description is required',
        });
        setLoading(false);
        return;
      }
      // if (!italianAddress) {
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'Error',
      //     text: 'Italian Address is required',
      //   });
      //   setLoading(false);
      //   return;
      // }
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      let imageUploadResponse; // Declare it outside the block

      if (formData.profileImage) {
        const data = new FormData();
        data.append('file', formData.profileImage);

        imageUploadResponse = await uploadFile(data, headers); // Assign value inside the block
        console.log(imageUploadResponse?.data?.data?.fileName);

        if (imageUploadResponse.status !== 200) {
          throw new Error('Image upload failed');
        }
      }

      const Categorydata = {
        image: imageUploadResponse?.data?.data?.fileName || userData?.image,
        city,
        category,
        name,
        address,
        description,
        phoneNo,
        location,
        // italianName,
        italianDescription,
        // italianAddress,
      };

      const Categoryheaders = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await updateTravelGuide(userData?._id, Categorydata, Categoryheaders);

      Swal.fire({
        icon: 'success',
        title: 'Travel Guide updated successfully',
      });

      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `${error?.response?.data?.errors[0]?.message}`,
      });
    }
  };
  return (
    <>
      <Helmet>
        <title> update Travel Guide </title>
      </Helmet>
      <Card sx={{ padding: 3 }}>
        <Stack
          onClick={() => navigate(-1)}
          gap={3}
          sx={{ cursor: 'pointer', mb: 3 }}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <FaArrowLeftLong size={24} />
          <Typography variant="h4">Update Travel Guide</Typography>
        </Stack>

        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { width: '50ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <Stack spacing={{ xs: 2, sm: 4 }} direction="row" useFlexGap flexWrap="wrap">
            <Stack>
              <FormControl sx={{ width: 490 }}>
                <FormLabel sx={{ mb: 1 }}>City</FormLabel>
                {/* <InputLabel id="demo-simple-select-label">BnB</InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={city}
                  // label="BnB"
                  onChange={handleChangeCity}
                >
                  {cities?.map((user) => (
                    <MenuItem key={user._id} value={user.name}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>

            <Stack>
              <FormControl sx={{ width: 490 }}>
                <FormLabel sx={{ mb: 1 }}>Category</FormLabel>
                {/* <InputLabel id="demo-simple-select-label">BnB</InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={category}
                  // label="BnB"
                  onChange={(e) => setCategory(e.target.value)}
                >
                  {categories?.map((user) => (
                    <MenuItem key={user._id} value={user.categoryName}>
                      {user.categoryName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Stack>

          <Stack mt={3} spacing={{ xs: 2, sm: 4 }} direction="row" useFlexGap flexWrap="wrap">
            <Stack>
              <FormLabel sx={{ mb: 1 }}>Name</FormLabel>
              <TextField
                label="Enter Name"
                id="outlined-size-small"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Stack>
            {/* <Stack>
              <FormLabel sx={{ mb: 1 }}>Italian Name</FormLabel>
              <TextField
                label="Enter Italian Name"
                id="outlined-size-small"
                value={italianName}
                onChange={(e) => setItalianName(e.target.value)}
              />
            </Stack> */}
             <Stack>
              <FormLabel sx={{ mb: 1 }}>Address</FormLabel>
              <TextField
                label="Enter Address"
                id="outlined-size-small"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Stack>
          </Stack>
          {/* <Stack mt={3} spacing={{ xs: 2, sm: 4 }} direction="row" useFlexGap flexWrap="wrap">
         
            <Stack>
              <FormLabel sx={{ mb: 1 }}>Address</FormLabel>
              <TextField
                label="Enter Address"
                id="outlined-size-small"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Stack>
            <Stack>
              <FormLabel sx={{ mb: 1 }}>Italian Address</FormLabel>
              <TextField
                label="Enter Italian Address"
                id="outlined-size-small"
                value={italianAddress}
                onChange={(e) => setItalianAddress(e.target.value)}
              />
            </Stack>
          </Stack> */}

          <Stack mt={3} spacing={{ xs: 2, sm: 4 }} direction="row" useFlexGap flexWrap="wrap">
            <Stack>
              <FormLabel sx={{ mb: 1 }}>Phone No</FormLabel>
              <TextField
                label="Enter Phone No"
                id="outlined-size-small"
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
              />
            </Stack>
            <Stack>
              <FormLabel sx={{ mb: 1 }}>location</FormLabel>
              <TextField
                label="Enter location"
                id="outlined-size-small"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </Stack>
          </Stack>

          <Stack mt={3} spacing={{ xs: 2, sm: 4 }} direction="row" useFlexGap flexWrap="wrap">
            <Stack>
              <FormLabel sx={{ mb: 1 }}>Description</FormLabel>
              <TextField
                multiline
                rows={4}
                label="Enter Description"
                id="outlined-size-small"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Stack>
            <Stack>
              <FormLabel sx={{ mb: 1 }}>Italian Description</FormLabel>
              <TextField
                multiline
                rows={4}
                label="Enter Italian Description"
                id="outlined-size-small"
                value={italianDescription}
                onChange={(e) => setItalianDescription(e.target.value)}
              />
            </Stack>
          </Stack>

          <Stack
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 2,
              marginTop: 2,
            }}
          >
            <Stack>
              {!formData.profileImage ? (
                <img
                  src={`${appConfig.FILE_URL}${userData?.image}`}
                  alt="img"
                  style={{ maxWidth: '300px', borderRadius: 10 }}
                />
              ) : (
                <img
                  src={URL.createObjectURL(formData.profileImage)}
                  alt="img"
                  style={{ maxWidth: '300px', borderRadius: 10 }}
                />
              )}
            </Stack>
            <Button
              sx={{
                width: 170,
                color: '#3A5239',
                borderColor: '#3A5239',
                '&:hover': {
                  borderColor: '#3A5239',
                  boxShadow: 'none',
                },
              }}
              component="label"
              variant="outlined"
              size="medium"
              startIcon={<SlCloudUpload />}
            >
              upload Image
              <input
                type="file"
                accept="image/*"
                name="profileImage"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            </Button>
          </Stack>
        </Box>

        <LoadingButton
          sx={{
            width: 100,
            mt: 4,
            mb: 4,
            boxShadow: 'none',
            backgroundColor: '#3A5239',
            '&:hover': {
              backgroundColor: '#3A5239',
              boxShadow: 'none',
            },
          }}
          variant="contained"
          onClick={handleSave}
          loading={loading}
        >
          Update
        </LoadingButton>
      </Card>
    </>
  );
};

export default UpdateTravelGuide;
