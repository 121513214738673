import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams, Link, useLocation, Navigate } from 'react-router-dom';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { MdOutlineEdit } from 'react-icons/md';
import { CiTrash } from 'react-icons/ci';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
} from '@mui/material';
// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
// components
import Swal from 'sweetalert2';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections

// mock
import USERLIST from '../_mock/user';
import {
  deleteCategory,
  deleteQuestion,
  deleteUploads,
  getAllQuestions,
  deleteAssignRefill,
  deleteAroundUs,
  getAllAssignRefill,
} from '../helper/backend_helper';
import appConfig from '../config';
import { SolutionListHead, SolutionListToolbar } from '../components/addSolution';

// ----------------------------------------------------------------------
// const users = [
//   {
//     pic: 'http://localhost:3000/assets/images/avatars/avatar_default.jpg',
//     title: 'Lorem Ipsum dolor sit',
//   },
//   {
//     pic: 'http://localhost:3000/assets/images/avatars/avatar_default.jpg',
//     title: 'Lorem Ipsum dolor sit',
//   },
//   {
//     pic: 'http://localhost:3000/assets/images/avatars/avatar_default.jpg',
//     title: 'Lorem Ipsum dolor sit',
//   },
//   {
//     pic: 'http://localhost:3000/assets/images/avatars/avatar_default.jpg',
//     title: 'Lorem Ipsum dolor sit',
//   },
//   {
//     pic: 'http://localhost:3000/assets/images/avatars/avatar_default.jpg',
//     title: 'Lorem Ipsum dolor sit',
//   },
//   {
//     pic: 'http://localhost:3000/assets/images/avatars/avatar_default.jpg',
//     title: 'Lorem Ipsum dolor sit',
//   },

// ];

const TABLE_HEAD = [
  { id: '', label: 'BnB', alignRight: true },
  { id: '', label: 'Refill Item Name', alignRight: true },
  { id: '', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function AssignRefill() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [clickedRowData, setClickedRowData] = useState(null);
  // const [users, setUsers] = useState([]);
  const [users, setUsers] = useState([]);
console.log(users)
  async function fetchData() {
    try {
      setLoading(true);
      const usersResponse = await getAllAssignRefill();
      const filteredUsers = usersResponse?.data?.data;
      setUsers(filteredUsers);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (event, row) => {
    try {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: 'Are you sure you want to delete this Data?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
      });
      if (confirmed.isConfirmed) {
        const headers = {
          headers: {
            'Content-Type': 'application/json',
          },
        };

        const header = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
        const data = {
          filename: row?.image,
        };
        // const delUploads = await deleteUploads(data , header);

        const response = await deleteAssignRefill(row?._id, headers);
        Swal.fire({
          icon: 'success',
          title: ' deleted successfully',
        });
        fetchData();
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'An error occur while deleting FAQ',
      });
    }
  };

  const [loading, setLoading] = useState(false);

  const handleOpenMenu = (event, row) => {
    setOpen(event.currentTarget);
    setClickedRowData(row);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  // useEffect(() => {
  //   let isMounted = true;

  //   const fetchData = async () => {
  //     try {
  //       setLoading(true);
  //       const response = await getUsers();

  //       if (isMounted) {
  //         setUsers(response.data.data.result);
  //       }

  //       setLoading(false);
  //     } catch (error) {
  //       setLoading(false);
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchData();

  //   // Cleanup function
  //   return () => {
  //     isMounted = false;
  //   };
  // }, []);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;
  // const encodedData = encodeURIComponent(JSON.stringify(clickedRow));
  return (
    <>
      <Helmet>
        <title> Assign Refill </title>
      </Helmet>

      <Card sx={{ padding: 3 }}>
        <Stack mt={3} direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4">Assign Refill</Typography>

          <Button
            sx={{
              marginBottom: 3,
              boxShadow: 'none',
              backgroundColor: '#3A5239',
              '&:hover': {
                backgroundColor: '#3A5239',
                boxShadow: 'none',
              },
            }}
            variant="contained"
            endIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => navigate('/assignRefill/add_assignRefill')}
          >
            Add Assign Refill
          </Button>
        </Stack>

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <SolutionListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={users.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  const { _id, bnb, refillItem } = row;

                  return (
                    <TableRow hover key={_id}>
                      <TableCell align="left">
                        { bnb?.map((item, index) => (
                              <span key={index}>
                                ● {item}
                                <br />
                              </span>
                            ))}
                      </TableCell>

                      <TableCell align="left">{refillItem?.name}</TableCell>
                   

                      <TableCell align="center">
                        <Stack
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <Button
                            onClick={() => navigate('/assignRefill/update_assignRefill', { state: { userData: row } })}
                            sx={{
                              boxShadow: 'none',
                              backgroundColor: '#B5FFD8',
                              color: '#009245',
                              '&:hover': {
                                backgroundColor: '#B5FFD8',
                                boxShadow: 'none',
                              },
                            }}
                            size="small"
                            variant="contained"
                            startIcon={<MdOutlineEdit />}
                          >
                            Edit
                          </Button>
                          <Button
                            onClick={(event) => handleDelete(event, row)}
                            sx={{
                              boxShadow: 'none',
                              backgroundColor: '#FFC6C6',
                              color: '#DC2626',
                              '&:hover': {
                                backgroundColor: '#FFC6C6',
                                boxShadow: 'none',
                              },
                            }}
                            size="small"
                            variant="contained"
                            startIcon={<CiTrash />}
                          >
                            Delete
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
                {loading && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={7}>
                      <CircularProgress size="20px" />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[50, 100, 200]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {/*     
        <Link
          
          style={{ textDecoration: 'none' }}
          // to={`/dashboard/user-detail/${encodeURIComponent(JSON.stringify(clickedRow))}`}
          to={`/dashboard/user-detail?data=${encodedData}`}
        > */}
        <MenuItem
          onClick={() => navigate('/contractor/contractor-detail', { state: { clickedRowData } })}
          sx={{ color: 'success.main' }}
        >
          <Iconify icon={'eva:image-outline'} sx={{ mr: 2 }} />
          Detail
        </MenuItem>
        {/* </Link> */}
        <MenuItem>
          <Iconify icon={'eva:edit-outline'} sx={{ mr: 2 }} />
          Block
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
