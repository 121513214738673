import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { SlCloudUpload } from 'react-icons/sl';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';
import { createCategory, createDoorList, createUsers, getAllRoles, getBnbList, uploadFile } from '../../helper/backend_helper';

const AddRole = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ profileImage: null });
  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState("");
  const [loading, setLoading]=useState(false);
  const handleFileChange = (e) => {
    const { name, files } = e.target;

    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const [users, setUsers] = useState([]);
  console.log('users', users);
  async function fetchData() {
    try {
      const usersResponse = await getAllRoles();
      console.log('usersResponse', usersResponse);
      setUsers(usersResponse?.data?.data?.roles);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleSave = async () => {
    try {
      setLoading(true);

      if (!name) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Name is required',
        });
        setLoading(false);
        return;
      }

      if (!email) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Email is required',
        });
        setLoading(false);
        return;
      }

      if (!mobileNumber) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Phone Number is required',
        });
        setLoading(false);
        return;
      }

      if (!password) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'password is required',
        });
        setLoading(false);
        return;
      }

      if (!role) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Role is required',
        });
        setLoading(false);
        return;
      }
      
    
    
      const Categorydata = {
        name,
        role,
        email,
        mobileNumber,
        password,
      };

      const Categoryheaders = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await createUsers(Categorydata, Categoryheaders);
      console.log('response', response);

      // Show success message
      Swal.fire({
        icon: 'success',
        title: 'User created successfully',
      });
      setName("")
      setEmail("")
      setMobileNumber("")
      setPassword("")
      setRole("")
      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `${error?.response?.data?.errors[0]?.message}`,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title> User </title>
      </Helmet>
      <Card sx={{ padding: 3 }}>
        <Stack
          onClick={() => navigate(-1)}
          gap={3}
          sx={{ cursor: 'pointer', mb: 3 }}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <FaArrowLeftLong size={24} />
          <Typography variant="h4">Add User</Typography>
        </Stack>

        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { width: '50ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <Stack spacing={{ xs: 2, sm: 4 }} direction="row" useFlexGap flexWrap="wrap">
            <Stack>
              <FormLabel sx={{ mb: 1 }}>Name</FormLabel>
              <TextField
                label="Enter Name"
                id="outlined-size-small"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Stack>
            <Stack>
              <FormLabel sx={{ mb: 1 }}>Email</FormLabel>
              <TextField
                label="Enter Email"
                id="outlined-size-small"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Stack>
            <Stack>
              <FormLabel sx={{ mb: 1 }}>Password</FormLabel>
              <TextField
                label="Enter Password"
                id="outlined-size-small"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Stack>
            <Stack>
              <FormLabel sx={{ mb: 1 }}>Phone Number</FormLabel>
              <TextField
              type='number'
                label="Enter Phone Number"
                id="outlined-size-small"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
              />
            </Stack>
          

            <Stack>
              <FormControl sx={{ width: 490 }}>
                <FormLabel sx={{ mb: 1 }}>Role</FormLabel>
                {/* <InputLabel id="demo-simple-select-label">BnB</InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={role}
                  // label="BnB"
                  onChange={(e) => setRole(e.target.value)}
                >
                  {users?.map((user) => (
                    <MenuItem key={user._id} value={user._id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>

         
      

          </Stack>

        </Box>
        <LoadingButton
          sx={{
            width: 100,
            mt: 4,
            mb: 4,
            boxShadow: 'none',
            backgroundColor: '#3A5239',
            '&:hover': {
              backgroundColor: '#3A5239',
              boxShadow: 'none',
            },
          }}
          variant="contained"
          onClick={handleSave}
          loading={loading}
        >
          Save
        </LoadingButton>
      </Card>
    </>
  );
};

export default AddRole;
