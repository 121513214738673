import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { SlCloudUpload } from 'react-icons/sl';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';
import {
  createCategory,
  createDoorList,
  getBnbList,
  updateDoorlist,
  uploadFile,
  getAllCity,
  updateCategory,
} from '../../helper/backend_helper';
import appConfig from '../../config';

const UpdateCategory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = location?.state?.userData;

  const [formData, setFormData] = useState({ profileImage: null });
  const [city, setCity] = useState(userData?.city);
  const [categoryName, setCategoryName] = useState(userData?.categoryName);
  const [description, setDescription] = useState(userData?.description);
  const [italianDescription, setItalianDescription] = useState(userData?.italianDescription);
  const [loading, setLoading] = useState(false);
  const handleFileChange = (e) => {
    const { name, files } = e.target;

    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const [users, setUsers] = useState([]);
  console.log('users', users);
  async function fetchData() {
    try {
      const usersResponse = await getAllCity();
      setUsers(usersResponse?.data?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleSave = async () => {
    try {
      setLoading(true);
      if (!categoryName) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Category Name is required',
        });
        setLoading(false);
        return;
      }

      if(city.length === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'City is required',
        });
        setLoading(false);
        return;
      }

      if (!description) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Description is required',
        });
        setLoading(false);
        return;
      }
      if(!italianDescription) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Italian Description is required',
        });
        setLoading(false);
        return;
      }
      // Prepare headers for multipart form data
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      let imageUploadResponse; // Declare it outside the block

      if (formData.profileImage) {
        const data = new FormData();
        data.append('file', formData.profileImage);

        imageUploadResponse = await uploadFile(data, headers); // Assign value inside the block
        console.log(imageUploadResponse?.data?.data?.fileName);

        if (imageUploadResponse.status !== 200) {
          throw new Error('Image upload failed');
        }
      }

      // Build the Categorydata object
      const Categorydata = {
        categoryImage: imageUploadResponse?.data?.data?.fileName || userData?.categoryImage, // Use the uploaded image or fallback
        categoryName,
        description,
        city,
        italianDescription
      };

      const Categoryheaders = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await updateCategory(userData?._id, Categorydata, Categoryheaders);

      // Show success message
      Swal.fire({
        icon: 'success',
        title: 'Category updated successfully',
      });
      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `${error?.response?.data?.errors[0]?.message}`,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Update Category </title>
      </Helmet>
      <Card sx={{ padding: 3 }}>
        <Stack
          onClick={() => navigate(-1)}
          gap={3}
          sx={{ cursor: 'pointer', mb: 3 }}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <FaArrowLeftLong size={24} />
          <Typography variant="h4">Update Category</Typography>
        </Stack>

        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { width: '50ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <Stack spacing={{ xs: 2, sm: 4 }} direction="row" useFlexGap flexWrap="wrap">
             <Stack>
             <FormControl sx={{ width: 490 }}>
              <FormLabel sx={{ mb: 1 }}>City</FormLabel>
              <Select
                labelId="demo-multi-select-label"
                id="demo-multi-select"
                multiple
                value={city} 
                onChange={(e) => setCity(e.target.value)}
                renderValue={(selected) => selected.join(', ')} 
              >
                {users?.map((user) => (
                  <MenuItem key={user._id} value={user.name}>
                    <Checkbox checked={city.indexOf(user.name) > -1} />
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </Stack>
          </Stack> 
          <Stack sx={{ mt: 3 }}>
            <FormLabel sx={{ mb: 1 }}>Category Name</FormLabel>
            <TextField
              label="Enter Category Name"
              id="outlined-size-small"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
            />
          </Stack>

          <Stack sx={{ mt: 3 }}>
            <FormLabel sx={{ mb: 1 }}>Description</FormLabel>
            <TextField
              label="Enter Description"
              id="outlined-size-small"
              value={description}
              multiline
              rows={4}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Stack>

          <Stack sx={{ mt: 3 }}>
            <FormLabel sx={{ mb: 1 }}>Italian Description</FormLabel>
            <TextField
              label="Enter Italian Description"
              id="outlined-size-small"
              value={italianDescription}
              multiline
              rows={4}
              onChange={(e) =>setItalianDescription(e.target.value)}
            />
          </Stack>

          <Stack
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 2,
              marginTop: 2,
            }}
          >
            <Stack>
              {!formData.profileImage ? (
                <img
                  src={`${appConfig.FILE_URL}${userData?.categoryImage}`}
                  alt="img"
                  style={{ maxWidth: '250px', borderRadius: 20,backgroundColor:"#3A5239",padding:5 }}
                />
              ) : (
                <img
                  src={URL.createObjectURL(formData.profileImage)}
                  alt="img"
                  style={{ maxWidth: '250px', borderRadius: 20,backgroundColor:"#3A5239",padding:5 }}
                />
              )}
            </Stack>
            <Button
              sx={{
                width: 170,
                color: '#3A5239',
                borderColor: '#3A5239',
                '&:hover': {
                  borderColor: '#3A5239',
                  boxShadow: 'none',
                },
              }}
              component="label"
              variant="outlined"
              size="medium"
              startIcon={<SlCloudUpload />}
            >
              upload Image
              <input
                type="file"
                accept="image/*"
                name="profileImage"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            </Button>
          </Stack>
        </Box>
        <LoadingButton
          sx={{
            width: 100,
            mt: 4,
            mb: 4,
            boxShadow: 'none',
            backgroundColor: '#3A5239',
            '&:hover': {
              backgroundColor: '#3A5239',
              boxShadow: 'none',
            },
          }}
          variant="contained"
          onClick={handleSave}
          loading={loading}
        >
          Update
        </LoadingButton>
      </Card>
    </>
  );
};

export default UpdateCategory;
