import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';

import DailyGuidePage from './pages/DailyGuidePage';
import NotificationPage from './pages/NotificationPage';
import DashboardAppPage from './pages/DashboardAppPage';








import ContactUsPage from './pages/ContactUsPage';
import ContactUsDetail from './components/contactus/ContactUsDetail';
import LogoutPage from './pages/LogoutPage';
import ChatPage from './pages/ChatPage';
import ContractorPositionPage from './pages/ContractorPositionPage';
import CreatePosition from './components/constractorPosition/CreatePosition';
import AddUser from './components/user/addUser';
import AddSolutionPage from './pages/AddSolutionPage';
import AddSolution from './components/addSolution/addSolution';
import AnnouncementPage from './pages/AnnouncementPage';
import Categories from './pages/DoorList';
import AddCategories from './components/doorList/AddCategories';
import UpdateUser from './components/user/updateUser';
import UpdateDoorList from './components/doorList/UpdateCategory';
import UpdateSolution from './components/addSolution/updateSolution';

import Banner from './pages/Banner';
import FAQs from './pages/FAQsPage';
import AddFAQs from "./components/addFaqs/AddFAQs"
import AuthToken from "./pages/authTokenPage"
import UpdateFAQs from "./components/addFaqs/UpdateFAQs"


import SpecialAccess from './pages/SpecialAccess';
import AddSpecialAccess from './components/specialAccess/AddSpecialAccess';
import UpdateSpecialAccess from "./components/specialAccess/UpdateSpecialAccess"


import TravelGuide from "./pages/TravelGuidePage"
import AddTravelGuide from "./components/travelGuide/addTravelGuide"
import UpdateTravelGuide from "./components/travelGuide/updateTravelGuide"


import City from "./pages/City" 
import AddCity from "./components/city/AddCity"
import UpdateCity from "./components/city/UpdateCity"



import AssignCity from "./pages/assignCity" 
import AddAssignCity from "./components/assignCity/AddAssignCity"
import UpdateAssignCity from "./components/assignCity/UpdateAssignCity"


import Category from "./pages/categoryPage" 
import AddCategory from "./components/category/AddCategory"
import UpdateCategory from "./components/category/UpdateCategory"



import Maintance from "./pages/Maintance" 


import Texi from "./pages/TexiPage"
import AddTexi from "./components/texi/AddTexi"
import UpdateTexi from "./components/texi/UpdateTexi"




import AroundUs from "./pages/AroundUsPage"
import AddAroundUs from './components/aroundUs/addAroundUs';
import UpdateAroundUs from './components/aroundUs/updateAroundUs';




import RefillItems from "./pages/RefillItems"
import AddRefillItems from "./components/refillItems/AddRefillItems"
import UpdateRefillItems from './components/refillItems/UpdateRefillItems';


import AssignRefill from './pages/AssignRefill'; 
import AddAssignRefill from "./components/addAssignRefill/AddAssignRefill"
import UpdateAssingRefill from "./components/addAssignRefill/UpdateAssignRefill"



import RefillRequests from "./pages/RefillRequests"


import CleaningItems from "./pages/ClenaingItems"
import AddCleaningItems from './components/cleaningItems/AddCleaningItems';
import UpdateCleaningItem from "./components/cleaningItems/UpdateCleaningItems"



import AssignCleaning from "./pages/AssignCleaning"
import AddAssignCleaning from "./components/AssignCleaning/AddAssignCleaning"
import UpdateAssignCleaning from "./components/AssignCleaning/UpdateAssignCleaning"



import ExtraCleaningRequest from './pages/ExtraCleaningRequest';



import Users from "./pages/RolesPage"
import AddUsers from "./components/roles/AddRole"
import UpdateUsers from "./components/roles/UpdateRole"



import Cleaner from "./pages/Cleaner"
import AddCleaner from "./components/cleaner/AddCleaner"
import UpdateCleaner from "./components/cleaner/UpdateCleaner"


import CleaningReport from "./pages/CleaningReport"
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'bnbList', element: <UserPage /> },
        { path: 'processBookings', element: <AddSolutionPage /> },
        { path: 'announcement', element: <AnnouncementPage /> },
        { path: 'webappSettings', element: <Banner /> },
        { path: 'doorList', element: <Categories /> },
        { path: 'chat', element: <ChatPage /> },
        { path: 'notifications', element: <NotificationPage /> },
        { path: 'contact-us', element: <ContactUsPage /> },
        { path: 'new-invoice', element: <NotificationPage /> },
        { path: 'approved-invoice', element: <BlogPage /> },
        { path: 'logout', element: <LogoutPage /> },
        { path: 'contractor-positions', element: <ContractorPositionPage /> },
        { path: 'faqs', element: <FAQs /> },
        { path: 'authToken', element: <AuthToken /> },
        {path:"specialAccess",element:<SpecialAccess/>},
        {path:"travelGuide/info",element:<TravelGuide/>},
        {path:"travelGuide/city",element:<City/>},
        {path:"travelGuide/category",element:<Category/>},
        {path:"travelGuide/assignCity",element:<AssignCity/>},
        { path:'maintanance', element: <Maintance /> },
        {path:"texi",element:<Texi/>},
        {path:"aroundUs",element:<AroundUs/>},
        {path:"Refill Detail/refillItems",element:<RefillItems/>},
        {path:"Refill Detail/assignRefill",element:<AssignRefill/>},
        {path:"Refill Detail/refillRequest",element:<RefillRequests/>},

        {path:"cleaning/cleaningItems",element:<CleaningItems/>},
        {path:"cleaning/assignCleaning",element:<AssignCleaning/>},
        {path:"cleaning/cleaningRequest",element:<ExtraCleaningRequest/>},
        {path:"users",element:<Users/>},
        {path:"cleaner",element:<Cleaner/>},
        {path:"cleaningReport",element:<CleaningReport/>},
        // user routes

        // { path: 'user-detail', element: <UserDetail /> },
        // { path: 'job-Detail', element: <JobHistory /> },
        // { path: 'attendenceDetail', element: <AttendenceDetail /> },
        // { path: 'UiDesigner', element: <UiDesigner /> },
        // jobs routes

        // Active jobs routes

        // Complete jobs Page
        // { path: 'complete-job-Detail', element: <CompleteJobDetail/> },

        // contact us route
      ],
    },



    {
      path: '/users',
      element: <DashboardLayout />,
      children: [

         { path: 'add_user', element: <AddUsers /> },
         { path: 'update_user', element: <UpdateUsers /> },

      ],
    },



    {
      path: '/bnbList',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/user-detail" />, index: true },
        { path: 'add_bnbList', element: <AddUser /> },
        { path: 'update_bnbList', element: <UpdateUser/> },
        // { path: 'job-Detail', element: <JobHistory /> },
        // { path: 'attendenceDetail', element: <AttendenceDetail /> },
        // { path: 'UiDesigner', element: <UiDesigner /> },
      ],
    },
    {
      path: '/add_solution',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/user-detail" />, index: true },
         { path: 'add_newsolution', element: <AddSolution /> },
         { path: 'update_solution', element: <UpdateSolution /> },
        // { path: 'job-Detail', element: <JobHistory /> },
        // { path: 'all-bids', element: <Index /> },
        // { path: 'contractor-detail', element: <UserDetail /> },
        // { path: 'attendenceDetail', element: <AttendenceDetail /> },
        // { path: 'UiDesigner', element: <UiDesigner /> },
      ],
    },
    {
      path: '/specialAccess',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/user-detail" />, index: true },
         { path: 'add_specialAccesss', element: <AddSpecialAccess /> },
         { path: 'update_specialAccess', element: <UpdateSpecialAccess /> },
        // { path: 'job-Detail', element: <JobHistory /> },
        // { path: 'all-bids', element: <Index /> },
        // { path: 'contractor-detail', element: <UserDetail /> },
        // { path: 'attendenceDetail', element: <AttendenceDetail /> },
        // { path: 'UiDesigner', element: <UiDesigner /> },
      ],
    },




    {
      path: '/city',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/user-detail" />, index: true },
         { path: 'add_city', element: <AddCity/> },
         { path: 'update_city', element: <UpdateCity /> },
        // { path: 'job-Detail', element: <JobHistory /> },
        // { path: 'all-bids', element: <Index /> },
        // { path: 'contractor-detail', element: <UserDetail /> },
        // { path: 'attendenceDetail', element: <AttendenceDetail /> },
        // { path: 'UiDesigner', element: <UiDesigner /> },
      ],
    },

    {
      path: '/assignCity',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/user-detail" />, index: true },
         { path: 'add_assigncity', element: <AddAssignCity/> },
         { path: 'update_assigncity', element: <UpdateAssignCity /> },
        // { path: 'job-Detail', element: <JobHistory /> },
        // { path: 'all-bids', element: <Index /> },
        // { path: 'contractor-detail', element: <UserDetail /> },
        // { path: 'attendenceDetail', element: <AttendenceDetail /> },
        // { path: 'UiDesigner', element: <UiDesigner /> },
      ],
    },


    {
      path: '/cleaner',
      element: <DashboardLayout />,
      children: [

         { path: 'add_cleaner', element: <AddCleaner /> },
         { path: 'update_cleaner', element: <UpdateCleaner /> },

      ],
    },



    {
      path: '/category',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/user-detail" />, index: true },
         { path: 'add_category', element: <AddCategory/> },
         { path: 'update_category', element: <UpdateCategory /> },
        // { path: 'job-Detail', element: <JobHistory /> },
        // { path: 'all-bids', element: <Index /> },
        // { path: 'contractor-detail', element: <UserDetail /> },
        // { path: 'attendenceDetail', element: <AttendenceDetail /> },
        // { path: 'UiDesigner', element: <UiDesigner /> },
      ],
    },

    {
      path: '/refillItems',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/user-detail" />, index: true },
         { path: 'add_refillItems', element: <AddRefillItems /> },
         { path: 'update_refillItems', element: <UpdateRefillItems /> },
        // { path: 'job-Detail', element: <JobHistory /> },
        // { path: 'all-bids', element: <Index /> },
        // { path: 'contractor-detail', element: <UserDetail /> },
        // { path: 'attendenceDetail', element: <AttendenceDetail /> },
        // { path: 'UiDesigner', element: <UiDesigner /> },
      ],
    },

    {
      path: '/assignRefill',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/user-detail" />, index: true },
         { path: 'add_assignRefill', element: <AddAssignRefill /> },
         { path: 'update_assignRefill', element: <UpdateAssingRefill /> },
        // { path: 'job-Detail', element: <JobHistory /> },
        // { path: 'all-bids', element: <Index /> },
        // { path: 'contractor-detail', element: <UserDetail /> },
        // { path: 'attendenceDetail', element: <AttendenceDetail /> },
        // { path: 'UiDesigner', element: <UiDesigner /> },
      ],
    },

    {
      path: '/refillRequest',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/user-detail" />, index: true },
        // { path: 'create-job', element: <CreateJob /> },
        // { path: 'job-Detail', element: <JobHistory /> },
        // { path: 'all-bids', element: <Index /> },
        // { path: 'contractor-detail', element: <UserDetail /> },
        // { path: 'attendenceDetail', element: <AttendenceDetail /> },
        // { path: 'UiDesigner', element: <UiDesigner /> },
        // { path: 'active-job', element: <ActiveJob/> },
      ],
    },
    {
      path: '/info',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/user-detail" />, index: true },
         { path: 'add_travelGuide', element: <AddTravelGuide /> },
         { path: 'update_travelGuide', element: <UpdateTravelGuide /> },
        // { path: 'job-Detail', element: <JobHistory /> },
        // { path: 'all-bids', element: <Index /> },
        // { path: 'contractor-detail', element: <UserDetail /> },
        // { path: 'attendenceDetail', element: <AttendenceDetail /> },
        // { path: 'UiDesigner', element: <UiDesigner /> },
      ],
    },

    {
      path: '/cleaningItems',
      element: <DashboardLayout />,
      children: [

         { path: 'add_cleaningItems', element: <AddCleaningItems /> },
         { path: 'update_cleaningItems', element: <UpdateCleaningItem /> },

      ],
    },
    {
      path: '/assignCleaning',
      element: <DashboardLayout />,
      children: [

         { path: 'add_assignCleaning', element: <AddAssignCleaning /> },
         { path: 'update_assignCleaning', element: <UpdateAssignCleaning /> },

      ],
    },
    {
      path: '/cleaningRequest',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/user-detail" />, index: true },
        // { path: 'create-job', element: <CreateJob /> },
        // { path: 'job-Detail', element: <JobHistory /> },
        // { path: 'all-bids', element: <Index /> },
        // { path: 'contractor-detail', element: <UserDetail /> },
        // { path: 'attendenceDetail', element: <AttendenceDetail /> },
        // { path: 'UiDesigner', element: <UiDesigner /> },
        // { path: 'active-job', element: <ActiveJob/> },
      ],
    },
    {
      path: '/announcement',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/user-detail" />, index: true },
        // { path: 'create-job', element: <CreateJob /> },
        // { path: 'job-Detail', element: <JobHistory /> },
        // { path: 'all-bids', element: <Index /> },
        // { path: 'contractor-detail', element: <UserDetail /> },
        // { path: 'attendenceDetail', element: <AttendenceDetail /> },
        // { path: 'UiDesigner', element: <UiDesigner /> },
        // { path: 'active-job', element: <ActiveJob/> },
      ],
    },
    {
      path: '/doorList',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/user-detail" />, index: true },
         { path: 'add_doorList', element: <AddCategories /> },
         { path: 'update-doorList', element: <UpdateDoorList /> },
        // { path: 'job-Detail', element: <JobHistory /> },
        // { path: 'all-bids', element: <Index /> },
        // { path: 'contractor-detail', element: <UserDetail /> },
        // { path: 'attendenceDetail', element: <AttendenceDetail /> },
        // { path: 'UiDesigner', element: <UiDesigner /> },
        // { path: 'active-job', element: <ActiveJob/> },
        // { path: 'complete-job-Detail', element: <CompleteJobDetail/> },
      ],
    },
    {
      path: '/faqs',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/user-detail" />, index: true },
         { path: 'add_faqs', element: <AddFAQs /> },
         { path: 'update_faqs', element: <UpdateFAQs /> },
        // { path: 'job-Detail', element: <JobHistory /> },
        // { path: 'all-bids', element: <Index /> },
        // { path: 'contractor-detail', element: <UserDetail /> },
        // { path: 'attendenceDetail', element: <AttendenceDetail /> },
        // { path: 'UiDesigner', element: <UiDesigner /> },
        // { path: 'active-job', element: <ActiveJob/> },
        // { path: 'complete-job-Detail', element: <CompleteJobDetail/> },
      ],
    },


    {
      path: '/aroundUs',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/user-detail" />, index: true },
         { path: 'add_aroundsUs', element: <AddAroundUs /> },
         { path: 'update_aroundsUs', element: <UpdateAroundUs /> },
        // { path: 'job-Detail', element: <JobHistory /> },
        // { path: 'all-bids', element: <Index /> },
        // { path: 'contractor-detail', element: <UserDetail /> },
        // { path: 'attendenceDetail', element: <AttendenceDetail /> },
        // { path: 'UiDesigner', element: <UiDesigner /> },
        // { path: 'active-job', element: <ActiveJob/> },
        // { path: 'complete-job-Detail', element: <CompleteJobDetail/> },
      ],
    },



    {
      path: '/webappSettings',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/user-detail" />, index: true },
        // { path: 'create-job', element: <CreateJob /> },
        // { path: 'job-Detail', element: <JobHistory /> },
        // { path: 'all-bids', element: <Index /> },
        // { path: 'contractor-detail', element: <UserDetail /> },
        // { path: 'attendenceDetail', element: <AttendenceDetail /> },
        // { path: 'UiDesigner', element: <UiDesigner /> },
        // { path: 'active-job', element: <ActiveJob/> },
      ],
    },
    {
      path: '/maintanance',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/user-detail" />, index: true },
        // { path: 'create-job', element: <CreateJob /> },
        // { path: 'job-Detail', element: <JobHistory /> },
        // { path: 'all-bids', element: <Index /> },
        // { path: 'contractor-detail', element: <UserDetail /> },
        // { path: 'attendenceDetail', element: <AttendenceDetail /> },
        // { path: 'UiDesigner', element: <UiDesigner /> },
        // { path: 'active-job', element: <ActiveJob/> },
      ],
    },
    {
      path: '/texi',
      element: <DashboardLayout />,
      children: [
        { path: 'add_texi', element: <AddTexi /> },
        { path: 'update_texi', element: <UpdateTexi /> },
        // { element: <Navigate to="/user-detail" />, index: true },
        // { path: 'create-job', element: <CreateJob /> },
        // { path: 'job-Detail', element: <JobHistory /> },
        // { path: 'all-bids', element: <Index /> },
        // { path: 'contractor-detail', element: <UserDetail /> },
        // { path: 'attendenceDetail', element: <AttendenceDetail /> },
        // { path: 'UiDesigner', element: <UiDesigner /> },
        // { path: 'active-job', element: <ActiveJob/> },
      ],
    },

    {
      path: '/contact-us',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/user-detail" />, index: true },
        // { path: 'create-job', element: <CreateJob /> },
        // { path: 'job-Detail', element: <JobHistory /> },
        // { path: 'all-bids', element: <Index /> },
        // { path: 'contractor-detail', element: <UserDetail /> },
        // { path: 'attendenceDetail', element: <AttendenceDetail /> },
        // { path: 'UiDesigner', element: <UiDesigner /> },
        // { path: 'active-job', element: <ActiveJob/> },
        // { path: 'complete-job-Detail', element: <CompleteJobDetail/> },
        // { path: 'contactUs-Detail', element: <ContactUsDetail/> },
      ],
    },
    {
      path: '/contractor-positions',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/user-detail" />, index: true },
        { path: 'create-position', element: <CreatePosition /> },
      ],
    },

    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
