import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Stack } from '@mui/material';
// components
import CircularProgress from '@mui/material/CircularProgress';
import Iconify from '../components/iconify';
import user from '../assets/user-icon.png';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { getAnnouncement, getCategories, getSolutions, getUsers } from '../helper/backend_helper';
// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const [users, setUsers] = useState();
  const [solution, setSolution] = useState();
  const [announcement, setannouncement] = useState();
  const [category, setCategory] = useState();
  const [loading, setLoading] = useState(false);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const usersResponse = await getUsers();
      const filteredUsers = usersResponse?.data?.data.filter((user) => user.role.name === 'user');
      setUsers(filteredUsers.length);
      // setUsers(usersResponse?.data?.data?.length)
      const solutionResponse = await getSolutions();
      setSolution(solutionResponse?.data?.length);
      const announcementResponse = await getAnnouncement();
      setannouncement(announcementResponse?.data?.data?.length);
      const categoryResponse = await getCategories();
      setCategory(categoryResponse?.data?.data?.length);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>

      <Container sx={{ backgroundColor: '#ffffff', padding: 2 }} maxWidth="xl">
        <Stack sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
          <Typography variant="h4" sx={{ mb: 5 }}>
            Dashboard
          </Typography>
          <Typography variant="h4" sx={{ mb: 5 }}>
            Hi, Welcome back
          </Typography>
        </Stack>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Users"
              icon={'mdi-account'}
              total={`${users}` || 0}
              isLoading={loading}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Bookings" isLoading={loading}    total={`${solution}` || 0} color="info" icon={'mdi-plus-box'} />
          </Grid>

          {/* <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Announcement" total={announcement} color="error" icon={'mdi-bullhorn'} />
          </Grid> */}

          {/* <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Categories" isLoading={loading}   total={`${category}` || 0} color="primary" icon={'mdi-view-grid-plus'} />
          </Grid> */}
          {/* <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Contact Us" total={234} color="error" icon={'mdi-phone-classic'} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="New Invoices" total={234} color="success" icon={'mdi-library-books'} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Approved Invoices" total={234} color="success" icon={'mdi-library-plus'} />
          </Grid> */}

          {/* 
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Bug Reports" total={234} color="warning" icon={'ant-design:bug-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Bug Reports" total={234} color="info" icon={'ant-design:bug-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Bug Reports" total={234} icon={'ant-design:bug-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Bug Reports" total={234} color="error" icon={'ant-design:bug-filled'} />
          </Grid> */}

           <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Website Visits"
              subheader="(+43%) than last year"
              chartLabels={[
                '01/01/2003',
                '02/01/2003',
                '03/01/2003',
                '04/01/2003',
                '05/01/2003',
                '06/01/2003',
                '07/01/2003',
                '08/01/2003',
                '09/01/2003',
                '10/01/2003',
                '11/01/2003',
              ]}
              chartData={[
                {
                  name: 'Team A',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: 'Team B',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: 'Team C',
                  type: 'line',
                  fill: 'solid',
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Current Visits"
              chartData={[
                { label: 'America', value: 4344 },
                { label: 'Asia', value: 5435 },
                { label: 'Europe', value: 1443 },
                { label: 'Africa', value: 4443 },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

       

        
     

        
        </Grid>
      </Container>
    </>
  );
}
